import React from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";

const CommonButton = (props) => {
  const {
    onClick,
    buttonName,
    buttonColor,
    fullWidth,
    style,
    icon,
    variant,
    className,
    size,
    startIcon = "",
    loader,
    loading,
    endIcon = "",
    ...other
  } = props;
  return (
    <>
      <Button
        variant={variant ? variant : "contained"}
        color={buttonColor}
        onClick={onClick}
        fullWidth={fullWidth}
        sx={style}
        className={className}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size || "medium"}
        {...other}
      >
        <Stack
          gap={1}
          flexDirection={"row"}
          fontSize={{ xs: "12px !important", sm: "18px" }}
          padding={{ xs: "4px 10px", sm: "6px 10px" }}
        >
          {loader && loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {icon && (
                <Box margin={"auto"}>
                  {icon && (
                    <img src={icon} style={{ display: "flex" }} width={15} />
                  )}
                </Box>
              )}
            </>
          )}

          <Box margin={"auto"} display={'flex'}>{buttonName}</Box>
        </Stack>
      </Button>
    </>
  );
};

export default CommonButton;
