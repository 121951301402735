import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UploadProduct } from "../../../helper/Constants";
import CommonInput from "../../../components/common/Input";
import CommonButton from "../../../components/common/Button";
import { LiaCameraRetroSolid } from "react-icons/lia";
import CommonModal from "../../../components/common/Modal";
import {
  ProductAddByuser,
  brandListHandler,
  categoryData,
  subCategoreyData,
} from "../../../Service/User/ProductAdd.service";
import { useFormik } from "formik";
import { uploadProductIv } from "../../../helper/UserInitialvalue";
import { productUploadValidation } from "../../../helper/UserValidation";
import { errorNotification } from "../../../Utils/Notification";
import { useNavigate } from "react-router-dom";

function AddProduct() {
  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    currentComponent: "",
  });

  const [file, setFile] = useState();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("productImage", formik.values.productImage);
    formData.append("brand", formik.values.brand);
    formData.append("category", formik.values.category);
    formData.append("productName", formik.values.productName);
    formData.append("subCategory", formik.values.subCategory);
    const { message, status } = await ProductAddByuser(formData);
    if (status) {
      setIsModalOpen({
        open: true,
        currentComponent: "upload-product",
      });
      formik.resetForm();
      setFile(null);
      setTimeout(()=>{
        navigate(`/`);
      },2000);
    } else {
      errorNotification(message);
    }
  };

  const formik = useFormik({
    initialValues: uploadProductIv,
    validationSchema: productUploadValidation,
    onSubmit: handleSubmit,
  });

  function handleChange(e) {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        errorNotification(
          "File size exceeds 2MB. Please select a smaller file."
        );
      } else {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        if (allowedTypes.indexOf(file.type) === -1) {
          errorNotification("Invalid file type. select a valid image file.");
        } else {
          setFile(URL.createObjectURL(e.target.files[0]));
          formik.setValues({
            ...formik.values,
            productImage: e.target.files[0],
          });
        }
      }
    }
  }

  //  below States for dropDown list
  const [categoreyData, setCategoreyData] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");

  const [subCateData, setSubCatData] = useState([]);
  //   const [selectedSubCate, setSelectedSubCat] = useState("");

  const [brandData, setBrandData] = useState([]);
  //   const [selectedBrand, setSelectdeBrand] = useState("");

  // we will get list of categorey Here
  const categoryHandler = async () => {
    const { data, message, status } = await categoryData();
    if (status) {
      setCategoreyData(data);
    } else {
      setCategoreyData([]);
    }
  };

  // For Select categorey
  const selectCategoreyHandler = (event) => {
    formik.setValues({
      ...formik.values,
      category: event.target.value,
    });
    setSelectedCat(event.target.value);
    subCategoreyHandler(event.target.value);
    brandHandler(event.target.value);
  };

  //  Based on Categorey id we will fetch subCategorey
  const subCategoreyHandler = async (id) => {
    const { data, message, status } = await subCategoreyData(id);
    if (status) {
      setSubCatData(data);
    } else {
      setSubCatData([]);
    }
  };

  // Based on Categorey id we will fetch Brand
  const brandHandler = async (id) => {
    const { data, message, status } = await brandListHandler(id);
    if (status) {
      setBrandData(data);
    } else {
      setBrandData([]);
    }
  };

  useEffect(() => {
    categoryHandler();
  }, []);

  return (
    <Box sx={{ maxHeight: "1000px", maxWidth: "1440px", margin: "auto" }}>
      <Box sx={{ margin: { xs: "0 0 20px", sm: "" } }} height={"100%"}>
        <Grid
          container
          gap={3}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ margin: { sm: "0 0 50px 0" } }}
        >
          <Grid
            item
            xs={12}
            md={5.5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img src={UploadProduct} style={{ width: "80%", height: "80%" }} />
          </Grid>
          <Grid item xs={12} md={5.5} sx={{}}>
            <Grid
              container
              gap={{xs:2,sm:3}}
              sx={{
                border: "1px solid #a4a4a4",
                maxWidth: "500px",
                padding: { xs: "30px 15px", sm: "60px 50px 70px 50px" },
                borderRadius: "25px",
                margin: "auto",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  fontSize={"24px"}
                  fontWeight={"500"}
                  textAlign={"center"}
                >
                  Upload Product
                </Typography>
              </Grid>
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Box
                  sx={{
                    width: "110px",
                    height: "110px",
                    background: "#80BD9E",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    marginBottom: "20px",
                  }}
                >
                  {file ? (
                    <img
                      src={file}
                      width={110}
                      height={110}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <LiaCameraRetroSolid
                      style={{ fontSize: "50px", color: "#fff" }}
                    />
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                      top: "0px",
                      left: "0px",
                      borderRadius: "50%",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleChange}
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          opacity: "0",
                          width: "100%",
                          height: "100%",
                          right: "0",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <FormHelperText> {formik.errors.productImage}</FormHelperText>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="Product Name"
                  name="productName"
                  formik={formik}
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={300}
                  marginBottom={"6px"}
                >
                  categorey
                </Typography>
                <Select
                  value={selectedCat}
                  onChange={selectCategoreyHandler}
                  displayEmpty
                  fullWidth
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    color: "#a4a4a4",
                  }}
                  className={"compare_select"}
                >
                  <MenuItem value="" hidden disabled>
                    {"None"}
                  </MenuItem>
                  {categoreyData.map((data, i) => {
                    return (
                      <MenuItem value={data._id} key={i}>
                        {data.masterCategoryName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText> {formik.errors.category} </FormHelperText>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={300}
                  marginBottom={"6px"}
                >
                  sub Category
                </Typography>
                <Select
                  displayEmpty
                  {...formik.getFieldProps("subCategory")}
                  fullWidth
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    color: "#a4a4a4",
                  }}
                  className={"compare_select"}
                >
                  <MenuItem value="" hidden disabled>
                    {"None"}
                  </MenuItem>
                  {subCateData.map((data, i) => {
                    return (
                      <MenuItem value={data._id} key={i}>
                        {data.categoryName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText> {formik.errors.subCategory} </FormHelperText>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={300}
                  marginBottom={"6px"}
                >
                  Brand
                </Typography>
                <Select
                  displayEmpty
                  fullWidth
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    color: "#a4a4a4",
                  }}
                  className={"compare_select"}
                  {...formik.getFieldProps("brand")}
                >
                  <MenuItem value="" hidden disabled>
                    {"None"}
                  </MenuItem>
                  {brandData.map((data, i) => {
                    return (
                      <MenuItem value={data._id} key={i}>
                        {data.brandName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText> {formik.errors.brand}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <CommonButton
                  buttonName="Upload"
                  fullWidth
                  onClick={() => formik.handleSubmit()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </Box>
  );
}

export default AddProduct;
