import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT,USER_LOADED,AUTH_ERROR } from '../action-types/authActionType';
import axiosRequest from "../axios/axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const loadUserApi = () => async (dispatch) => {
  if (localStorage.token) {
    axiosRequest.defaults.headers.common[
      "Authorization"
    ] = `${localStorage.token}`;
  }
  try {
    const res = await axiosRequest.post(`token`);
    if (res.data && res.data.status === true) {
      dispatch({
        type: USER_LOADED,
        payload: res.data.userdata
      });
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
    return { success: false, message: err.response.data.message };
  }
};

export const loginUserApi = (payload, rememberMe) => async (dispatch) => {
  try {
    const res = await axiosRequest.post(`${payload.route}`, payload);
    const body = JSON.stringify(payload);
    if (res.data && res.data.status == true) {

      if (rememberMe === true) {
        cookies.set("token", res.data.data.token, { path: "/" });
        cookies.set("email", payload.email, { path: "/" });
        cookies.set("password", payload.password, { path: "/" });
      } else {
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: LOGIN_FAILURE,
      });
    }
    return res?.data;
  } catch (error) {
    return { success: false, message: error.response.data.message };
  }
   
}

export const logoutApi = () => async (dispatch) => {
  
    dispatch({ type: LOGOUT });
}

export const SignUp= async (payload) => {
    try {
      const response = await axiosRequest.post(`/adminRegister`, payload);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
};

export const ForgotPasswords = async (payload) => {
  try {
    const response = await axiosRequest.put(`/adminEmailCheck`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const AdminResetPassword = async (payload) => {
  try {
    const response = await axiosRequest.put(`/adminForgotPassword`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

export const ChangePasswords = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`/changeAdminPassword/${id}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
};

