import React, { useEffect, useState } from "react";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../components/common/SearchBar";
import CommonButton from "../../../components/common/Button";
import { slugNameHandler } from "../../../helper/Helper";
import {
  getFuncationListByNamem,
  getIngredientDetailsBySearch,
  getIngredientsFunctionHandler,
} from "../../../Service/IngredientService";
import Loader from "../../../components/common/loader/Loader";

const Ingredients = () => {
  const [funcationData, setFuncationData] = useState([]);
  const [PackagefuncationData, setPackageFuncationData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [packageSelectedData, setpackageSelectedData] = useState([]);
  const [totalIngredientData, setTotalIngredientData] = useState();
  const [totalPackageIngredientData, setTotalPackageIngredientData] =
    useState();
  const [selectedFlag, setSelectedFlag] = useState(false);
  const [productFlag, setProductFlag] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");

  const [masterSearchText, setMasterSearchText] = useState("");
  const [selectFunctionName, setSelectedFuncationName] = useState("");
  // //
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalProductData, setTotalProductData] = useState();
  const [pagePerSize, setPagePerSize] = useState();
  // Display Count For
  const [displayCount, setDisplayCount] = useState(10);
  const increment = 10;

  const [displayCounts, setDisplayCounts] = useState(10);
  const increments = 10;
  const [globalLoader, setGlobalLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getIngredientsFunctionList();
  }, []);

  const getIngredientsFunctionList = async () => {
    try {
      setGlobalLoader(true);
      const { data, message, status } = await getIngredientsFunctionHandler();
      if (status) {
        setFuncationData(data?.cosmetic?.functions);
        setTotalIngredientData(data?.cosmetic.total);
        setPagePerSize(data.perPageSize);
        setPackageFuncationData(data?.package?.functions);
        setTotalPackageIngredientData(data?.package.total);
      } else {
        setFuncationData([]);
        setPackageFuncationData([]);
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in getIngredientsFunctionList:", error);
    } finally {
      setGlobalLoader(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  const selecteDataHandler = async (name, page) => {
    try {
      setGlobalLoader(true);
      const payload = {
        functionName: name,
        page,
      };
      const { data, status } = await getIngredientDetailsBySearch("", payload);
      if (status) {
        setSelectedFlag(true);
        setSelectedData(data.ingredients);
        setTotalIngredientData(data.total);
        setPagePerSize(data.perPageSize);
      } else {
        setSelectedFlag(true);
        setSelectedData([]);
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in selecteDataHandler:", error);
    } finally {
      setGlobalLoader(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  const selectePackageDataHandler = async (name, page) => {
    try {
      setGlobalLoader(true);
      setSelectedName("");
      setSelectedProductName((prevName) => (prevName === name ? "" : name));
      const payload = {
        functionName: name,
        page,
      };
      const { data, status } = await getIngredientDetailsBySearch("", payload);
      if (status) {
        setProductFlag(true);
        setSelectedFlag(true);
        setSelectedData(data.ingredients);
        setTotalIngredientData(data.total);
        setPagePerSize(data.perPageSize);
      } else {
        setProductFlag(true);
        setSelectedFlag(true);
        setSelectedData([]);
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in selectePackageDataHandler:", error);
    } finally {
      setGlobalLoader(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
    if (inputValue === "") {
      setMasterSearchText(inputValue);
      setSelectedFlag(false);
    }
  };

  const packageSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
    if (inputValue === "") {
      setMasterSearchText(inputValue);
      setSelectedFlag(false);
    }
  };

  const packageSearchClickHandler = async () => {
    try {
      setGlobalLoader(true);
      if (masterSearchText.trim() !== "") {
        const payload = {
          page: currentPage,
        };
        const { data, message, status } = await getIngredientDetailsBySearch(
          masterSearchText,
          payload
        );
        if (status) {
          setSelectedFlag(true);
          // setpackageSelectedData(data.ingredients);
          // setTotalPackageIngredientData(data.total);
          setSelectedData(data.ingredients);
          setTotalIngredientData(data.total);
          setPagePerSize(data.perPageSize);
        } else {
          setSelectedFlag(true);
          // setpackageSelectedData([]);
        setSelectedData([]);

        }
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in packageSearchClickHandler:", error);
    } finally {
      setGlobalLoader(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  const masterSearchClickHandler = async () => {
    try {
      setGlobalLoader(true);
      if (masterSearchText.trim() !== "") {
        const payload = {
          page: currentPage,
        };
        const { data, message, status } = await getIngredientDetailsBySearch(
          masterSearchText,
          payload
        );
        if (status) {
          setSelectedFlag(true);
          setSelectedData(data.ingredients);
          setTotalIngredientData(data.total);
          setPagePerSize(data.perPageSize);
        } else {
         
          setSelectedFlag(true);
          setSelectedData([]);
        }
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in masterSearchClickHandler:", error);
    } finally {
      setGlobalLoader(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    if (selectFunctionName) {
      selecteDataHandler(selectFunctionName, newPage);
    } else {
      masterSearchClickHandler(masterSearchText, newPage);
    }
  };

  const handlePageChanges = (event, newPage) => {
    setCurrentPage(newPage);
    if (selectFunctionName) {
      selectePackageDataHandler(selectFunctionName, newPage);
    } else {
      packageSearchClickHandler(masterSearchText, newPage);
    }
  };

  const seeMoreHandler = () => {
    setDisplayCount((prevCount) => prevCount + increment);
  };
  const seeMoreHandlers = () => {
    setDisplayCounts((prevCount) => prevCount + increments);
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 12px", sm: "0 20px", lg: "auto" }}
    >
      {globalLoader ? (
        <Loader />
      ) : (
        <>
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            gap={{ xs: 0, md: 3 }}
          >
            <Box sx={{ width: { xs: "100%", md: "50%" } }}>
              <Box padding={{ xs: "15px 0", sm: "30px 0 10px" }} width={"100%"}>
                <SearchBar
                  margin="unset"
                  placeholder="Search for Cosmetic Ingredient "
                  searchHandler={masterSearchHandler}
                  onClick={masterSearchClickHandler}
                />
              </Box>
              <Typography
                fontSize={{ xs: "16px", sm: "20px" }}
                fontWeight={"400"}
                marginBottom={"10px"}
              >
                Select Cosmetic Ingredient By Function
              </Typography>
              <Box
                margin={"0 0 30px"}
                sx={{
                  border: "1px solid #E9E9E9",
                  padding: { xs: "12px", sm: "30px" },
                  borderRadius: "20px",
                  position: "relative",
                }}
                width={"100%"}
              >
                <Stack
                  flexDirection={"row"}
                  gap={{ xs: 1, sm: 2 }}
                  flexWrap={"wrap"}
                >
                  {funcationData.slice(0, displayCount).map((data, i) => {
                    return (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                        sx={{
                          padding: { xs: "10px 12px", sm: "15px" },
                          borderRadius: "10px",
                          background:
                            selectedName === data.Name ? "#80BD9E" : "#f1f1f1",
                          color:
                            selectedName === data.Name ? "#fff" : "#a4a4a4",
                          // background: "#f1f1f1",
                          // color: "#a4a4a4",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          onClick={() => [
                            selecteDataHandler(data.Name, 1),
                            setSelectedFuncationName(data.Name),
                            setSelectedName(data.Name),
                            setSelectedProductName(""),
                          ]}
                        >
                          <Typography
                            fontSize={{ xs: "12px", sm: "14px", md: "16px" }}
                          >
                            {data.Name}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
                {displayCount < funcationData.length && (
                  <Box textAlign={"center"} mt={2}>
                    <CommonButton
                      size="small"
                      buttonName="see more"
                      style={{ padding: "7px 20px" }}
                      onClick={() => seeMoreHandler()}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ width: { xs: "100%", md: "50%" } }}>
              <Box padding={{ xs: "15px 0", sm: "30px 0 10px" }} width={"100%"}>
                <SearchBar
                  margin="unset"
                  placeholder="Search for Package food Ingredient"
                  searchHandler={packageSearchHandler}
                  onClick={packageSearchClickHandler}
                />
              </Box>
              <Typography
                fontSize={{ xs: "16px", sm: "20px" }}
                fontWeight={"400"}
                marginBottom={"10px"}
              >
                Select Package food Ingredient By Function
              </Typography>
              <Box
                margin={"0 0 30px"}
                sx={{
                  border: "1px solid #E9E9E9",
                  padding: { xs: "12px", sm: "30px" },
                  borderRadius: "20px",
                  position: "relative",
                }}
                width={"100%"}
              >
                <Stack
                  flexDirection={"row"}
                  gap={{ xs: 1, sm: 2 }}
                  flexWrap={"wrap"}
                >
                  {PackagefuncationData.slice(0, displayCounts).map(
                    (data, i) => {
                      return (
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          gap={1}
                          sx={{
                            padding: { xs: "10px 12px", sm: "15px" },
                            borderRadius: "10px",
                            background:
                              selectedProductName === data.Name
                                ? "#80BD9E"
                                : "#f1f1f1",
                            color:
                              selectedProductName === data.Name
                                ? "#fff"
                                : "#a4a4a4",
                            // background: "#f1f1f1",
                            // color: "#a4a4a4",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            onClick={() => [
                              selecteDataHandler(data.Name, 1),
                              setSelectedFuncationName(data.Name),
                              setSelectedName(""),
                              setSelectedProductName(data.Name),
                            ]}
                          >
                            <Typography
                              fontSize={{ xs: "12px", sm: "14px", md: "16px" }}
                            >
                              {data.Name}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Stack>
                {displayCounts < PackagefuncationData.length && (
                  <Box textAlign={"center"} mt={2}>
                    <CommonButton
                      size="small"
                      buttonName="see more"
                      style={{ padding: "7px 20px" }}
                      onClick={() => seeMoreHandlers()}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
          <Box>
            <Typography
              fontSize={"20px"}
              fontWeight={"400"}
              marginBottom={"10px"}
            >
              {selectedFlag && (
                <>{`${totalIngredientData} ingredients found`}</>
              )}
            </Typography>
            <Box>
              <Stack flexDirection={"row"} gap={2} flexWrap={"wrap"}>
                {selectedData.length > 0 &&
                  selectedData.map((res) => {
                    const slugName = slugNameHandler(res.ingredientName);
                    return (
                      <Box
                        width={{ xs: "100%", md: "49%" }}
                        sx={{
                          border: "1px solid #E9E9E9",
                          borderRadius: "10px",
                          padding: "12px 20px",
                        }}
                      >
                        <Stack
                          gap={2}
                          flexDirection={{ xs: "column", md: "row" }}
                          justifyContent={"space-between"}
                          alignItems={{ xs: "flex-start", md: "center" }}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/ingredient/${res._id}/${slugName}`);
                            // navigate("/product-ingredient",{
                            //   state:{
                            //     ingredientId : res._id,
                            //     origin : res.ingredientOrigin
                            //   }
                            // })
                          }}
                        >
                          <Box>
                            <Typography fontSize={"16px"} color={"#263238"}>
                              {res.ingredientName && res.ingredientName}
                            </Typography>
                            <Stack
                              flexDirection={"row"}
                              gap={"3px"}
                              flexWrap={"wrap"}
                            >
                              {res.ingredientFunctions &&
                                res.ingredientFunctions.length > 0 &&
                                res.ingredientFunctions.map((res) => {
                                  return (
                                    <Typography
                                      fontSize={"13px"}
                                      color={"#80BD9E"}
                                      sx={{ textDecoration: "underline" }}
                                    >
                                      {res},
                                    </Typography>
                                  );
                                })}
                            </Stack>
                          </Box>
                          <Stack
                            gap={3}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={{ xs: "flex-end", md: "center" }}
                            width={{ xs: "100%", md: "unset" }}
                          >
                            <CommonButton
                              buttonName={`${res.ingredientOrigin}`}
                              style={{
                                background:
                                  res.ingredientOrigin === "Natural"
                                    ? "#80BD9E"
                                    : res.ingredientOrigin === "Synthetic"
                                    ? "#FF7575"
                                    : res.ingredientOrigin === "Uncertain"
                                    ? "#FDD068"
                                    : "#FDD068",
                              }}
                            />
                            <Box
                              sx={{
                                border: "1px solid rgba(233, 233, 233, 0.80)",
                                borderRadius: "10px",
                                padding: 1,
                              }}
                            >
                              <Typography
                                fontSize={{ xs: "12px", sm: "16px" }}
                                textAlign={"center"}
                              >
                                {res.comedogenicity && res.comedogenicity}
                              </Typography>
                            </Box>
                          </Stack>
                        </Stack>
                      </Box>
                    );
                  })}
              </Stack>
            </Box>
            {selectedData.length > 0 && (
              <>
                <Box sx={{ padding: "10px 0 30px" }}>
                  <Pagination
                    count={Math.ceil(totalIngredientData / pagePerSize)}
                    variant="outlined"
                    shape="rounded"
                    page={currentPage}
                    //handlePageChanges
                    onChange={handlePageChange}

                    // onChange={productFlag ? handlePageChanges : handlePageChange}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Ingredients;
