import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { review } from '../../../../helper/Constants'

const Review = ({data}) => {
    return (
        <Box>
            <Typography fontSize={{ xs: '16px', sm: '22px' }} fontWeight={'500'} marginBottom={'10px'}>Research and Review</Typography>
            <Stack gap={2}>
                {
                    data && data.map((res) => {
                        return (
                            <Stack flexDirection={'row'} gap={2} sx={{ border: '1px solid #E9E9E9', padding: { xs: 1, sm: '15px 30px' }, borderRadius: '10px' }} alignItems={{ xs: 'flex-start', sm: 'center' }}>
                                <Box sx={{ background: '#F2F8F5', borderRadius: '10px', height: '50px', width: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={review} />
                                </Box>
                                <Box width={'fit-content'}>
                                    <Typography fontSize={{ xs: '13px', sm: '18px' }} fontWeight={400} color={'#959595'}>{res}</Typography>
                                </Box>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}

export default Review