import axios from "axios";
import { store } from "../store";

const axiosRequest = axios.create({
   
    baseURL: "https://api.hidenfind.com/api/v1", 
});

 // set token data

// axiosRequest.interceptors.request.use((config) => {
//   const state = store.getState();
//   const token = state.auth.token;
//   config.params = config.params || {};
//   config.headers["Authorization"] = token;
//   return config;
// });

export default axiosRequest;
