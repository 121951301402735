import React, { useState, useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CommonInput from "../../components/common/Input";
import CommonButton from "../../components/common/Button";
import { useForm } from "../../Utils/useForm";
import { DetailsUser, UserUpdate } from "../../state/action/usersAction";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const EditUserModal = ({ handleClose, ID }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default severity
  const navigation = useNavigate();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("firstName" in fieldValues) {
      temp.firstName = fieldValues.firstName ? "" : "This field is required.";
    }

    if ("lastName" in fieldValues) {
      temp.lastName = fieldValues.lastName ? "" : "This field is required.";
    }

    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [userDetails, setuserDetails] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const res = await DetailsUser(ID);
        // Assuming DetailsUser is defined
        if (res && res.status === true) {
          setuserDetails(res.data);
        } else {
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("An error occurred while fetching user details:", error);
      }
    };

    if (ID) {
      fetchUserDetails();
    }
  }, [ID]);

  const { values, errors, setValues, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const body = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      const res = await UserUpdate(ID, body);
      if (res && res.status === true) {
        handleClose();
        setSnackbarSeverity("success");
        setSnackbarMessage(res.message);
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage(res.message);
        setSnackbarOpen(true);
      }
    }
  };

  useEffect(() => {
    setValues({
      ...values,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
    });
  }, [userDetails]);
  return (
    <>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
          Edit User
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid
            container
            gap={2}
            width={{ xs: "250px", sm: "400px" }}
            paddingY={1}
          >
            <Grid item xs={12}>
              <CommonInput
                name="firstName"
                value={values.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                error={errors.firstName}
                helperText={errors.firstName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommonInput
                name="lastName"
                value={values.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                error={errors.lastName}
                helperText={errors.lastName}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommonInput
                placeholder="Email"
                value={values.email}
                type="email"
                name="email"
                onChange={handleInputChange}
                error={errors.email}
                helperText={errors.email}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CommonButton buttonName="Cancle" onClick={handleClose} />
          <CommonButton buttonName="Save" onClick={handleSubmit} />
        </DialogActions>
      </div>
      <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top", // Change this to 'bottom' if needed
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default EditUserModal;
