import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Like, Product1, Unlike } from "../../../helper/Constants";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { addToFavourite, productApiHandler, removeFromFavourite } from "../../../Service/Product";
import { errorNotification, successNotification } from "../../../Utils/Notification";

const ingredientOrigin = [
  {
    name: "Natural Ingredients",
    num: "5",
    numColor: "#80BD9E",
    per: "33%",
    perColor: "#f2f8f5",
  },
  {
    name: "Synthetic Ingredients",
    num: "5",
    numColor: "#ff7575",
    per: "33%",
    perColor: "#fff0f0",
  },
  {
    name: "Uncertain Ingredients",
    num: "5",
    numColor: "#ffd15b",
    per: "33%",
    perColor: "#fffaef",
  },
];

const ProductDetails = (props) => {
  const { productImage, productName, productCategoryName, productCategoryId, deleteIcon, productId, favouriteFlag, NaturalPercentage, SyntheticPercentage,
    UncertainPercentage,
    NaturalCount, SyntheticCount,
    UncertainCount, brandName, brandId } = props;
  const navigate = useNavigate();
  const [favourite, setFavourite] = React.useState(favouriteFlag);
  const userLogin = localStorage.getItem("userId");
  const handleClick = () => setFavourite((show) => !show);

  useEffect(() => {
    setFavourite(favouriteFlag);
  }, [favouriteFlag])

  const favouriteHandler = async () => {
    if (!userLogin) {
      navigate('/login')
      return;
    }
    const payload = {
      productId: productId,
      userId: userLogin
    }
    const { data, message, status } = await addToFavourite(payload);
    if (status) {
      successNotification(message);
      setFavourite((show) => !show);
    } else {
      errorNotification(message);
    }
  };

  const unFavouriteHandler = async () => {
    if (!userLogin) {
      navigate('/login')
      return;
    }
    const payload = {
      productId: productId,
      userId: userLogin
    }
    const { data, message, status } = await removeFromFavourite(payload);
    if (status) {
      setFavourite((show) => !show);
      successNotification(message);
    } else {
      errorNotification(message);
    }
  }
  const brandListHandler = (id) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: "",
        brand: id,
        // set product Name null if your not search from Product name
        productName: null,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: ""
      },
    });
  };

  const categoryListHandler = (id) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: "",
        brand: "",
        // set product Name null if your not search from Product name
        productName: null,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: id
      },
    });
  };

  return (
    <>
      <Typography fontSize={"20px"} fontWeight={"400"}>
        Product Details
      </Typography>
      <Box marginTop={1} marginBottom={3}>
        <Stack flexDirection={{ xs: "column", md: "row" }} gap={3}>
          <Box
            sx={{
              border: "1px solid #E9E9E9",
              padding: { xs: "30px 20px" },
              borderRadius: "20px",
              position: "relative",
              width: { xs: "100%", sm: "fit-content" },
            }}
          >
            <Box
              textAlign={"center"}
              height={"100%"}
              width={"fit-content"}
              margin={"auto"}
              display={"flex"}
              alignItems={"center"}
            >
              <img src={productImage} className="product_details_image" alt={productName} />
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "12px",
                right: "11px",
              }}
            >
              {deleteIcon ? (
                <DeleteIcon
                  sx={{ color: "#cdcccc", width: "18px", height: "18px" }}
                />
              ) : favourite ? (
                <img src={Like} onClick={unFavouriteHandler} />
              ) : (
                <img src={Unlike} onClick={favouriteHandler} />
              )}
            </Box>
          </Box>
          <Box width={"100%"}>
            <Stack gap={3}>
              <Box sx={{ border: "1px solid #E9E9E9", borderRadius: "20px" }}>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ padding: "15px 20px" }}
                >
                  <Typography
                    color={"#80BD9E"}
                    fontSize={{
                      xs: "16px",
                      sm: "18px",
                      md: "20px",
                      xl: "24px",
                    }}
                  >
                    {productName}{" "}
                  </Typography>
                  {/* need to keep this code commented */}
                  <Typography
                    color={"#435059"}
                    fontSize={{
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                      xl: "20px",
                    }}
                    onClick={() => brandListHandler(brandId)}
                    sx={{ textDecoration: "underline", cursor: 'pointer', }}
                  >
                    {brandName}
                  </Typography>
                </Stack>
                <Divider />
                <Stack sx={{ padding: "15px 20px" }}>
                  <Typography
                    color={"#435059"}
                    fontSize={{
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                      xl: "20px",
                    }}
                    onClick={() => categoryListHandler(productCategoryId)}

                    sx={{ textDecoration: "underline", cursor: 'pointer', }}
                  >
                    {productCategoryName}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Typography fontSize={'20px'} fontWeight={'400'} marginBottom={'5px'}>Ingredient Origin</Typography>
                <Box>
                  <Stack flexDirection={'row'} gap={2} justifyContent={'space-between'} flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
                    {/* {
                                            ingredientOrigin.map((data, i) => {
                                                return ( */}
                    <Box width={'100%'}>
                      <Box sx={{ border: '1px solid #E9E9E9', borderRadius: '20px', }}>
                        <Stack gap={1} sx={{
                          position: 'relative', flexDirection: 'row',
                          alignItems: 'center', justifyContent: 'center', padding: '40px 15px'
                        }}>
                          <Box sx={{
                            width: { xs: '50px', sm: '60px', lg: '70px', xl: '90px' },
                            height: { xs: '50px', sm: '60px', lg: '70px', xl: '90px' },
                            background: '#80BD9E', borderRadius: '50%',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                          }}>
                            <Typography sx={{ color: '#fff' }}
                              fontSize={{ xs: '22px', sm: '28px', xl: '34px' }}
                            >{NaturalCount}</Typography>
                          </Box>
                          <Box>
                            <Typography fontSize={{ xs: '16px', sm: '18px', xl: '22px' }}
                              width={'min-content'}>Natural Ingredients
                            </Typography>
                          </Box>
                          <Box sx={{
                            position: 'absolute', top: '10px', right: '10px',
                            background: '#f2f8f5', padding: '2px 15px', borderRadius: '5px'
                          }}>
                            <Typography fontSize={{ xs: '12px', sm: '15px', xl: '18px' }}
                              sx={{ color: '#80BD9E' }}>{NaturalPercentage && NaturalPercentage.toFixed(2)}%</Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Box width={'100%'}>
                      <Box sx={{ border: '1px solid #E9E9E9', borderRadius: '20px', }}>
                        <Stack gap={1} sx={{
                          position: 'relative', flexDirection: 'row',
                          alignItems: 'center', justifyContent: 'center', padding: '40px 15px'
                        }}>
                          <Box sx={{
                            width: { xs: '50px', sm: '60px', lg: '70px', xl: '90px' },
                            height: { xs: '50px', sm: '60px', lg: '70px', xl: '90px' },
                            background: '#ff7575', borderRadius: '50%',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                          }}>
                            <Typography sx={{ color: '#fff' }}
                              fontSize={{ xs: '22px', sm: '28px', xl: '34px' }}
                            >{SyntheticCount}</Typography>
                          </Box>
                          <Box>
                            <Typography fontSize={{ xs: '16px', sm: '18px', xl: '22px' }}
                              width={'min-content'}>Synthetic Ingredients
                            </Typography>
                          </Box>
                          <Box sx={{
                            position: 'absolute', top: '10px', right: '10px',
                            background: '#fff0f0', padding: '2px 15px', borderRadius: '5px'
                          }}>
                            <Typography fontSize={{ xs: '12px', sm: '15px', xl: '18px' }}
                              sx={{ color: '#ff7575' }}>{SyntheticPercentage && SyntheticPercentage.toFixed(2)}%</Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Box width={'100%'}>
                      <Box sx={{ border: '1px solid #E9E9E9', borderRadius: '20px', }}>
                        <Stack gap={1} sx={{
                          position: 'relative', flexDirection: 'row',
                          alignItems: 'center', justifyContent: 'center', padding: '40px 15px'
                        }}>
                          <Box sx={{
                            width: { xs: '50px', sm: '60px', lg: '70px', xl: '90px' },
                            height: { xs: '50px', sm: '60px', lg: '70px', xl: '90px' },
                            background: '#ffd15b', borderRadius: '50%',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                          }}>
                            <Typography sx={{ color: '#fff' }}
                              fontSize={{ xs: '22px', sm: '28px', xl: '34px' }}
                            >{UncertainCount}</Typography>
                          </Box>
                          <Box>
                            <Typography fontSize={{ xs: '16px', sm: '18px', xl: '22px' }}
                              width={'min-content'}>Uncertain Ingredients
                            </Typography>
                          </Box>
                          <Box sx={{
                            position: 'absolute', top: '10px', right: '10px',
                            background: '#fffaef', padding: '2px 15px', borderRadius: '5px'
                          }}>
                            <Typography fontSize={{ xs: '12px', sm: '15px', xl: '18px' }}
                              sx={{ color: '#ffd15b' }}>{UncertainPercentage && UncertainPercentage.toFixed(2)}%</Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ProductDetails;
