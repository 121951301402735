import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { slugNameHandler } from "../../../helper/Helper";
const IngredientsList = [
  { name: "Alcohol Denat", available: true },
  { name: "Alcohol Denatured", available: false },
  { name: "Butyl Alcohol", available: false },
  { name: "Coumarin", available: false },
  { name: "Hydroxycitronellal", available: true },
  { name: "Limonene", available: false },
  { name: "Alcohol Denatured", available: false },
  { name: "Butyl Alcohol", available: false },
  { name: "Coumarin", available: false },
  { name: "Hydroxycitronellal", available: true },
  { name: "Limonene", available: false },
  { name: "Alcohol Denatured", available: false },
  { name: "Butyl Alcohol", available: false },
  { name: "Coumarin", available: false },
  { name: "Limonene", available: false },
];
const Ingredients = ({ ingredientsData }) => {
  const navigate = useNavigate();
  if (ingredientsData.length === 0) {
    return (
      <Typography variant="h6" fontWeight="400" marginBottom="5px">
        No Ingredients Found
      </Typography>
    );
  }
  return (
    <>
      <Typography fontSize={"20px"} fontWeight={"400"} marginBottom={"5px"}>
        Ingredients
      </Typography>
      <Box
        margin={"0 0 30px"}
        sx={{
          border: "1px solid #E9E9E9",
          padding: { xs: "20px", sm: "30px" },
          borderRadius: "20px",
          position: "relative",
        }}
        width={"100%"}
      >
        <Stack
          flexDirection={"row"}
          gap={{ xs: "10px", sm: 2 }}
          flexWrap={"wrap"}
        >
          {ingredientsData.map((data, i) => {
            const slugName = slugNameHandler(data.ingredientName)
            return (
              <Box
                onClick={() => navigate(`/ingredient/${data._id}/${slugName}`)}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
                sx={{
                  padding: { xs: "10px 12px", sm: "15px" },
                  borderRadius: "10px",
                  background: data.ingredientOrigin === "Natural"
                    ? "#80BD9E"
                    : data.ingredientOrigin === "Synthetic"
                      ? "#FF7575"
                      : data.ingredientOrigin === "Uncertain"
                        ? "#FDD068"
                        : "#FDD068",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                <Box>
                  <Typography fontSize={{ xs: "12px", sm: "14px", md: "16px" }}>
                    {data.ingredientName}
                  </Typography>
                </Box>
                <InfoIcon
                  sx={{
                    width: { xs: "16px", sm: "20px" },
                    height: { xs: "16px", sm: "20px" },
                  }}
                />
              </Box>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export default Ingredients;
