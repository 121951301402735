import {get,post,patch,put,deleteRequest} from "../../web.request";

export const masterCategoryList = () =>{
    return get(`/categoryUserList`);
}

// Search Category By MasterCategorey
export const searchCategoryMId = (search,mId) =>{
    return get(`/categorySearch/${mId}?search=${search}`)
};

// Search Brand By MasterCategorey
export const searchBrandMId = (search,mId) =>{
    return get(`/brandSearch/${mId}?search=${search}`)
};

// Product List By Categories or Brand Id 
export const ProductListByIds = (page,search,data) =>{
    return post(`/categoryProductList/?page=${page}&search=${search}`,data)
};

// MasterSearch Api Handler 
export const masterProductSearchHandler = (page,searchText,data) =>{
    return get(`/userProductList/?page=${page}&search=${searchText}`,data);
};

// ProductDetails Api Handler 
export const productApiHandler = (id,payload) =>{
    return post(`/productDetail/${id}`,payload);
};

// Add To favourite 
export const addToFavourite = (data) =>{
    return post(`/addProductWishlist`,data);
};

// Remove From Favourite
export const removeFromFavourite = (data) =>{
    return deleteRequest(`/wishListProductdelete`,data);
}

// get List of Favourite List

export const favListHandler = (id) =>{
    return get(`/userWishList/${id}`);
}

