import React, { useEffect, useState } from "react";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import SearchBar from "../../../components/common/SearchBar";
import ProductBannerCard from "./ProductBannerCard";
import {
  ProductListByIds,
  masterProductSearchHandler,
} from "../../../Service/Product";
import ProductCard from "./ProductCard";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/loader/Loader";

function ProductSearch() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const userLogin = localStorage.getItem("userId");
  // get token from local storage bcoz need to pass in api
  const userToken = localStorage.getItem("userToken");
  // MasterCategorey List will be store in this useState
  const [masterCategoreyList, setMasterCategoreyList] = useState([]);
  // to search product
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProductData, setTotalProductData] = useState();
  const [pagePerSize, setPagePerSize] = useState();
  const [productsSearch, setProductsSearch] = useState("");
  const [masterSearchText, setMasterSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [productTextData, setProductTextData] = useState("");
  const [gloabalLoading, setGlobalLoading] = useState(false);

  // This UseEffect will Handle product List when state is coming and simple also
  useEffect(() => {
    setMasterSearchText(state?.ProductText || state?.productName);
    setSearchText(state?.ProductText || state?.productName)
    if (state && !state.productName) {
      // in this productListingHandler we will pass pageNumber and SearchText
      productListingHandler(currentPage, "");
    } else {
      // if we have productName in the state than we call Master Search Api Handler
      masterSearchTxtHandler(currentPage, state.productName);
    }
  }, []);

  //  productListingHandler we will accpet page and SearchText
  const productListingHandler = async (page, search) => {
    try {
      setLoading(true);
      setGlobalLoading(true);
      const payload = {
        subCategory: state.subCategory,
        brand: state.brand,
        // we will pass userToken if user is log in neiter blank
        token: userToken ? userToken : "",
        // here category means masterCategory
        category: state.category
      };
      // in the productListByIds we pass page,search,data
      const { data, message, status } = await ProductListByIds(
        page,
        search,
        payload
      );
      if (status) {
        setMasterCategoreyList(data.categoryData);
        setProducts(data.product);
        setTotalProductData(data.total);
        setPagePerSize(data.perPageSize);
      }
    } catch (error) {
      console.error("Error in productListingHandler:", error);
    } finally {
      setLoading(false);
      setGlobalLoading(false);
    }
  };
  useEffect(() => {
    if (state) {
      setProductTextData(state?.ProductText)
    }

  }, [state])

  const masterSearchHandler = (event) => {
    setMasterSearchText(event.target.value);
    const inputValue = event.target.value;
    if (inputValue === "") {
      masterSearchTxtHandler(currentPage, inputValue);
    }
  };

  const masterClickHandler = () => {
    if (masterSearchText.trim() != "") {
      masterSearchTxtHandler(currentPage, masterSearchText);
      setSearchText(masterSearchText)
    }
  };

  // master Search Api Handler
  const masterSearchTxtHandler = async (page, searchText) => {
    try {
      setLoading(true);
      setGlobalLoading(true);
      // pass userToken because needed in this api
      const payload = {
        userId: userLogin ? userLogin : "",
      };
      const { data, message, status } = await masterProductSearchHandler(
        page,
        searchText,
        payload
      );
      // Here we will set api response into Product Data
      if (status) {
        setMasterCategoreyList(data.categoryData);
        setProducts(data.productData);
        setTotalProductData(data.total);
        setPagePerSize(data.perPageSize);
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in masterSearchTxtHandler:", error);
    } finally {
      setLoading(false);
      setGlobalLoading(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    if (state && !state.productName) {
      productListingHandler(newPage, productsSearch);
    } else {
      masterSearchTxtHandler(newPage, masterSearchText)
    }
  };

  const productsSearchHandler = (event) => {
    const inputValue = event.target.value;
    setProductsSearch(inputValue);
    if (inputValue === "") {
      // Call Api when state is blank
      productListingHandler(currentPage, inputValue);
    }
  };

  const productClickHandler = () => {
    if (productsSearch.trim() !== "") {
      // call api for the Search
      productListingHandler(currentPage, productsSearch);
    }
  };

  const masterCategoreyHandler = async (id, name) => {
    try {
      setGlobalLoading(true);
      setMasterSearchText(name);
      setSearchText(name);
      const payload = {
        subCategory: "",
        brand: "",
        // we will pass userToken if user is log in neiter blank
        token: userToken ? userToken : "",
        // here category means masterCategory
        category: id
      }

      const { data, message, status } = await ProductListByIds(
        currentPage,
        productsSearch,
        payload
      );
      if (status) {
        setMasterCategoreyList(data.categoryData);
        setProducts(data.product);
        setTotalProductData(data.total);
        setPagePerSize(data.perPageSize);
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in masterCategoreyHandler:", error);
    } finally {
      setGlobalLoading(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform navigation or other actions here
    }
  };


  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 20px", lg: "auto" }}
    >
      {
        gloabalLoading ? <Loader /> : (
          <>
            <Box padding={"30px 0 10px"} width={"100%"}>
              {/* Master Search handler */}
              <SearchBar
                placeholder="What are you looking for? "
                searchHandler={masterSearchHandler}
                onClick={masterClickHandler}
                loader={true}
                loading={loading}
              />
            </Box>
            <Box padding={"30px 0"}>
              <ProductBannerCard
                highliteCard={productTextData}
                data={masterCategoreyList}
                masterCategoreyHandler={masterCategoreyHandler}
                setProductTextData={setProductTextData}

              />
            </Box>
            {products.length > 0 ? (
              <Box marginTop={2}>
                <Stack>
                  <Typography>{`${totalProductData} Results Found for ${searchText}`}</Typography>
                  <Box sx={{ padding: "10px 0 20px" }}>
                    <div className="grid_container">
                      {products?.map((res, index) => {
                        // res.flag will 1 Means we will show favourite neither unFavourite
                        const status = res.flag === 1 ? true : false;
                        return (
                          <div className="grid_item" key={`${index}`}>
                            <ProductCard
                              image={res.productImage && res?.productImage[0]}
                              name={res.productName}
                              id={res._id}
                              brand={res.brand}
                              // favouriteStatus we are passing True and False
                              favouriteStatus={status}
                              details={res.nutritionalInformation}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box marginTop={2}>
                <Typography>No Data Found</Typography>
              </Box>
            )}

            <Box sx={{ padding: "0 0 30px" }}>
              <Pagination
                // count={Math.max(1, Math.ceil(totalProductData / pagePerSize))}
                count={Math.ceil(totalProductData / pagePerSize)}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handlePageChange}
              />
            </Box>
          </>
        )
      }


      {/* as per client feedback remove this section */}
      {/* <Box padding={"30px 0 10px"} width={"100%"}>
        <SearchBar
          placeholder="Search for a product"
          searchHandler={productsSearchHandler}
          onClick={productClickHandler}
        />
      </Box> */}


    </Box>
  );
}

export default ProductSearch;
