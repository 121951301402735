import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SearchBar from "../../../../components/common/SearchBar";
import Categories from "../Categories";
import Brands from "../Brands";
import { searchBrandMId, searchCategoryMId } from "../../../../Service/Product";


const Cosmetic = (props) => {
  const {
    cosCtgSearch,
    cosCtgList,
    cosBrdSearch,
    catDataList,
    brdDataList,
    cosBrdList,
    mCategoreyName,
    masterId,
  } = props;

  // To display categoreyData & brandData 
  const [categoreyData, setCategoreyData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  // Store Searched CateGorey
  const [searchCat, setSearchCat] = useState("");
  // Store Searched Brand
  const [searBrand, setSearchBrand] = useState("");

  useEffect(() => {
    setCategoreyData(catDataList);
  }, [catDataList]);

  // store value of Categorey while Typing into useState for Api Call
  const catSearchHandler = (event) => {
    const inputValue = event.target.value;
    setSearchCat(event.target.value);
    // Need to Keep This Code if we need onChnage Api Call while Search
    // inputValue.length >= 3 ||
    if(inputValue === ""){
      catSearchApiHandler(event.target.value);
    }
  };

  // store value of Brand while Typing into useState for Api Call
  const brandSearchHandler = (event) => {
    const inputValue = event.target.value;
    setSearchBrand(event.target.value);
    // Need to Keep This Code if we need onChnage Api Call while Search
    // inputValue.length >=3 ||
    if(inputValue === ""){
      brandSearchApiHandler(event.target.value)
    }
  };

  // Here we will call api for search by cat
  const catSearchApiHandler = async (searchText) => {
    const { data, message, status } = await searchCategoryMId(
      searchText,
      masterId
    );
    if (status) {
      setCategoreyData(data);
    }
  };

  //  Here we will call api for search by Brand
  const brandSearchApiHandler = async (searchText) => {
    const { data, message, status } = await searchBrandMId(
      searchText,
      masterId
    );
    if (status) {
      setBrandData(data);
    }
  };

  // call Api when user click on search Button
  const catSearchClickHandler = () => {
    if (searchCat.trim() !== "") {
      catSearchApiHandler(searchCat);
    }
  };

  // call Api when user click on search Button
  const brandSearchClickHandler = () => {
    if (searBrand.trim() !== "") {
      brandSearchApiHandler(searBrand);
    }
  };

  useEffect(() => {
    setBrandData(brdDataList);
  }, [brdDataList]);

  return (
    <>
      {cosCtgList && (
        <Box>
          {cosCtgSearch && (
            <Box padding={"10px 0 10px"}>
              <Typography
                fontSize={{ xs: "16px", sm: "20px" }}
                fontWeight={"400"}
                marginBottom={"10px"}
              >{`Browse By ${mCategoreyName} Categories`}</Typography>
              <SearchBar
                margin="unset"
                searchHandler={catSearchHandler}
                placeholder={`Search for a ${mCategoreyName} category `}
                onClick={catSearchClickHandler}
              />
            </Box>
          )}
          {cosCtgList && <Categories CategorieList={categoreyData} />}
        </Box>
      )}

      {cosBrdList && (
        <Box>
          {cosBrdSearch && (
            <Box padding={"0 0 20px"}>
              <Typography
                fontSize={{ xs: "16px", sm: "20px" }}
                fontWeight={"400"}
                marginBottom={"10px"}
              >{`Browse By ${mCategoreyName} Brands`}</Typography>
              <SearchBar
                margin="unset"
                placeholder={`Search for a ${mCategoreyName} brand `}
                searchHandler={brandSearchHandler}
                onClick={brandSearchClickHandler}
              />
            </Box>
          )}
          {cosBrdList && <Brands BrandList={brandData} />}
        </Box>
      )}
    </>
  );
};

export default Cosmetic;
