import React from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const CommonInput = (props) => {
  const {
    type,
    placeholder,
    passwordInput,
    inputProps,
    labelInput,
    labelColor,
    error,
    formik,
    variant,
    labal,
    name,
    onChange,
    value,
    disabled,
  } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      {labelInput && (
        <Typography
          fontSize={"14px"}
          fontWeight={600}
          marginBottom={"6px"}
          color={labelColor}
        >
          {labal}
        </Typography>
      )}
      {passwordInput ? (
        <div className="p_relative">
          <TextField
            fullWidth
            hiddenLabel
            disabled={disabled}
            variant={variant ? variant : "outlined"}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            inputProps={inputProps}
            error={error}
            helperText={props?.formik?.errors[props.name]}
            {...props?.formik?.getFieldProps(props.name)}
            {...props}
            type={showPassword ? "text" : "password"}
          />
          <InputAdornment position="end" className="eye_btn">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        </div>
      ) : (
        <TextField
          fullWidth
          hiddenLabel
          disabled={disabled}
          variant={variant ? variant : "outlined"}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          inputProps={inputProps}
          error={error}
          type={type || "text"}
          helperText={props?.formik?.errors[props.name]}
          {...props?.formik?.getFieldProps(props.name)}
          {...props}
        />
      )}
    </>
  );
};

export default CommonInput;
