import React from 'react'
import './loader.css'

const Loader = () => {
    return (
        <div className="loading-screen">
            <div className="chaotic-orbit"></div>
            {/* <p className='Abril_Fatface'>Hide n Find</p> */}
        </div>

    )
}

export default Loader