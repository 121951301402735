import { IconButton, InputBase, Paper } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CommonButton from './Button';
const SearchBar = (props) => {
    const { margin, placeholder, onClick, searchHandler } = props

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onClick();
        }
    };

    return (
        <Paper
            component="form"
            sx={{ p: { xs: '0 5px', sm: '2px 6px' }, display: 'flex', alignItems: 'center', maxWidth: 600, margin: `${margin ? margin : 'auto'}`, background: '#F6F6F6', boxShadow: 'unset' }}
        >
            {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search"> */}
            <SearchIcon sx={{ m: { xs: '10px 0px', sm: '12px 5px' }, color: '#a4a4a4' }} />
            {/* </IconButton> */}
            <InputBase
                sx={{ m: '0 10px', flex: 1, fontSize: { xs: '13px', sm: '16px' } }}
                onChange={searchHandler}
                placeholder={placeholder}
                inputProps={{ 'aria-label': `${placeholder}` }}
                onKeyDown={handleKeyDown}
            />
            <CommonButton buttonName="Search" style={{ padding: { xs: '4px 20px', sm: '5px 20px' } }} onClick={onClick} />
        </Paper>
    )
}

export default SearchBar