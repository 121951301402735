import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from '../../pages/User/Home/Home';
import Product from '../../pages/User/Product/Product';
import Profile from '../../pages/User/Profile/Profile';
import History from '../../pages/User/history/History';
import Wishlist from '../../pages/User/wishlist/Wishlist';
import CompareProduct from '../../pages/User/compare/CompareProduct';
import ProductDetails from '../../pages/User/product-details/ProductDetails';
import CosmeticDetails from '../../pages/User/product-details/cosmetic/CosmeticDetails';
import FoodDetails from '../../pages/User/product-details/food/FoodDetails';
import ProductSearch from '../../pages/User/Product/ProductSearch';
import ProductIngredients from '../../pages/User/Product/productingredients/Ingredients';
import Brand from '../../pages/User/brand/Brand';
import Ingredients from '../../pages/User/ingredients/Ingredients';
import UploadProduct from '../../pages/User/upload-product/UploadProduct';

function Main() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/product" element={<Product />} />
      <Route exact path="/products-search" element={<ProductSearch />} />
      {/* consider CosmeticDetails as ProductDetails */}
      <Route exact path="/product-details" element={<CosmeticDetails />} />
      <Route
        exact
        path="/product-ingredient"
        element={<ProductIngredients />}
      />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/history" element={<History />} />
      <Route exact path="/favourites" element={<Wishlist />} />
      <Route exact path="/comparison" element={<CompareProduct />} />
      <Route exact path="/brands" element={<Brand />} />
      <Route exact path="/ingredients" element={<Ingredients />} />
      <Route exact path="/upload-product" element={<UploadProduct />} />
    </Routes>
  );
}

export default Main;
