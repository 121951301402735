import React, { useState, useEffect } from "react";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  Avatar
} from "@mui/material";
import imageNotFound from "../../assets/images/imageNotFound.png"
import CloseIcon from "@mui/icons-material/Close";
import { DetailsUser } from "../../state/action/usersAction";
const UserView = ({ handleClose, ID }) => {
  // details
  const [userDetails, setuserDetails] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const res = await DetailsUser(ID);
        // Assuming DetailsUser is defined
        if (res && res.status === true) {
          setuserDetails(res.data);
        } else {
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("An error occurred while fetching user details:", error);
      }
    };

    if (ID) {
      fetchUserDetails();
    }
  }, [ID]);

  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Details of Users
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          gap={2}
          width={{ xs: "250px", sm: "400px" }}
          paddingY={1}
        >
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                  <Box
                    sx={{
                      padding: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {userDetails.profile ? (
                      <Avatar
                        src={userDetails.profile}
                        sx={{
                          width: "200px",
                          height: "100px",
                          borderRadius: "unset",
                        }}
                      />
                    ) : (
                      <Avatar src={imageNotFound} />
                    )}
                  </Box>
                </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#f1f1f1",
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <Grid container paddingBottom={1}>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"13px"} fontWeight={600}>
                    First Name :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"13px"}>
                    {userDetails.firstName || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container paddingBottom={1}>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"13px"} fontWeight={600}>
                    Last Name :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"13px"}>
                    {" "}
                    {userDetails.lastName || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container paddingBottom={1}>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"13px"} fontWeight={600}>
                    Email :
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography fontSize={"13px"}>
                    {userDetails.email || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
};

export default UserView;
