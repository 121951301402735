import React from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
const IngredientsList = [
  { name: "Alcohol DenatSugar", nutrition: "73.1 g" },
  { name: "Alcohol DenatSugar", nutrition: "73.1 g" },
  { name: "Alcohol DenatSugar", nutrition: "73.1 g" },
  { name: "Alcohol DenatSugar", nutrition: "73.1 g" },
  { name: "Alcohol DenatSugar", nutrition: "73.1 g" },
  { name: "Alcohol DenatSugar", nutrition: "73.1 g" },
];

const NutritionInfo = ({ nutritionData }) => {
  return (
    <>
      <Typography fontSize={"20px"} fontWeight={"400"} marginBottom={"5px"}>
        Nutrition Information per 100g{" "}
      </Typography>
      <Box
        margin={"0 0 30px"}
        sx={{
          border: "1px solid #E9E9E9",
          borderRadius: "20px",
          position: "relative",
        }}
        width={"100%"}
      >
        <Box>
          {nutritionData &&
            nutritionData.map((data, i) => {
              return (
                <>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    gap={1}
                    sx={{ padding: { xs: "15px 20px", sm: "20px 30px" } }}
                  >
                    <Box>
                      <Typography
                        fontSize={{ xs: "14px", sm: "15px", md: "16px" }}
                      >
                        {data}
                      </Typography>
                    </Box>
                    {/* <Box>
                    <Typography
                      fontSize={{ xs: "14px", sm: "15px", md: "16px" }}
                    >
                      {data.nutrition}
                    </Typography>
                  </Box> */}
                  </Stack>
                  <Divider />
                </>
              );
            })}
        </Box>
        {/* <Box padding={{ xs: '15px 20px', sm: '20px 30px' }} sx={{ color: '#80BD9E', textAlign: 'center' }}>
          Read More
        </Box> */}
      </Box>
    </>
  );
};

export default NutritionInfo;
