import "./App.css";
import { useNavigate } from "react-router-dom";
import PrivateRoute from "../src/Routes/PrivateRoute";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/User/Login/Login";
import { Box, ThemeProvider } from "@mui/material";
import { theme } from "./helper";
import Signup from "./pages/User/Login/Signup";
import ForgotPassword from "./pages/User/Login/ForgotPassword";
import ScrollToTop from "./helper/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetUserPassword from "./pages/User/Login/ResetUserPassword";
import Home from "./pages/User/Home/Home";
import Layout from "./components/Userlayout/Layout";
import Product from "./pages/User/Product/Product";
import ProductSearch from "./pages/User/Product/ProductSearch";
import CosmeticDetails from "./pages/User/product-details/cosmetic/CosmeticDetails";
import ProductIngredients from "./pages/User/Product/productingredients/Ingredients";
import Profile from "./pages/User/Profile/Profile";
import History from "./pages/User/history/History";
import Wishlist from "./pages/User/wishlist/Wishlist";
import CompareProduct from "./pages/User/compare/CompareProduct";
import Brand from "./pages/User/brand/Brand";
import Ingredients from "./pages/User/ingredients/Ingredients";
import UploadProduct from "./pages/User/upload-product/UploadProduct";
import ProductDetailsSlug from "./pages/User/ProductDetailsSlug";
import IngredientsBySlug from "./pages/User/Product/productingredients/IngredientsBySlug";
import Loader from "./components/common/loader/Loader";
import Axios from 'axios'
// import  from "axios";

function App() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        {isLoading ? <Loader /> :
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signUp" element={<Signup />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route
              exact
              path="/resetpassword/:token"
              element={<ResetUserPassword />}
            />
            <Route
              exact
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              exact
              path="/product"
              element={
                <Layout>
                  <Product />
                </Layout>
              }
            />
            <Route
              exact
              path="/products-search"
              element={
                <Layout>
                  <ProductSearch />
                </Layout>
              }
            />
            <Route
              exact
              path="/product-details"
              element={
                <Layout>
                  <CosmeticDetails />
                </Layout>
              }
            />
            <Route
              exact
              path="/product/:id/:name"
              element={
                <Layout>
                  <ProductDetailsSlug />
                  {/* <Product /> */}
                </Layout>
              }
            />
            <Route
              exact
              path="/ingredient/:id/:name"
              element={
                <Layout>
                  <IngredientsBySlug />
                </Layout>
              }
            />
            <Route
              exact
              path="/product-ingredient"
              element={
                <Layout>
                  <ProductIngredients />
                </Layout>
              }
            />
            {/* For The Private Route */}
            <Route element={<PrivateRoute />}>
              <Route
                exact
                path="/profile"
                element={
                  <Layout>
                    <Profile />
                  </Layout>
                }
              />

              <Route
                exact
                path="/history"
                element={
                  <Layout>
                    <History />
                  </Layout>
                }
              />

              <Route
                exact
                path="/favourites"
                element={
                  <Layout>
                    <Wishlist />
                  </Layout>
                }
              />
            </Route>
            <Route
              exact
              path="/comparison"
              element={
                <Layout>
                  <CompareProduct />
                </Layout>
              }
            />
            <Route
              exact
              path="/brands"
              element={
                <Layout>
                  <Brand />
                </Layout>
              }
            />
            <Route
              exact
              path="/ingredients"
              element={
                <Layout>
                  <Ingredients />
                </Layout>
              }
            />
            <Route
              exact
              path="/upload-product"
              element={
                <Layout>
                  <UploadProduct />
                </Layout>
              }
            />
          </Routes>}
        <ToastContainer />
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
