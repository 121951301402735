import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ChangePassword from "../../pages/modal/ChangePassword";
import Logout from "../../pages/modal/Logout";
import StatusChange from "../../pages/modal/Status";
import UserDetails from "../../pages/modal/UserDetails";
import DeleteRecord from "../../pages/modal/DeleteRecord";
import AdminLogout from "../../pages/modal/AdminLogout";
import EditUserModal from "../../pages/modal/EditUserModal";
import Uploadsuccess from "../../pages/User/upload-product/modal/Uploadsuccess";
import SearchProduct from "../../pages/User/compare/SearchProduct";
import ReportError from "../../pages/modal/ReportError";

export default function CommonModal({
  handleClose,
  modalOpen,
  statuschange,
  switchId,
  UserId,
  DeletesetId,
  ID,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === "changePassword" && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === "logout" && (
        <Logout handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === "searchProduct" && (
        <SearchProduct handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === "reportError" && (
        <ReportError handleClose={handleClose} modalOpen={modalOpen} />
      )}

      {modalOpen.currentComponent === "StatusChange" && (
        <StatusChange
          handleClose={handleClose}
          modalOpen={modalOpen}
          statuschange={statuschange}
          switchId={switchId}
        />
      )}
      {modalOpen.currentComponent === "DeleteRecords" && (
        <DeleteRecord
          handleClose={handleClose}
          modalOpen={modalOpen}
          DeletesetId={DeletesetId}
        />
      )}
      {modalOpen.currentComponent === "userDetails" && (
        <UserDetails handleClose={handleClose} modalOpen={modalOpen} ID={ID} />
      )}
      {modalOpen.currentComponent === "Adminlogout" && (
        <AdminLogout handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {modalOpen.currentComponent === "EditUsers" && (
        <EditUserModal
          handleClose={handleClose}
          modalOpen={modalOpen}
          ID={ID}
        />
      )}
      {modalOpen.currentComponent === "upload-product" && (
        <Uploadsuccess handleClose={handleClose} modalOpen={modalOpen} />
      )}
    </Dialog>
  );
}
