import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import CommonButton from "../../../components/common/Button";
import CommonInput from "../../../components/common/Input";
import { getUserDataById, profileUpdatebyId } from "../../../Service/User/Profile.service";
import { useFormik } from "formik";
import { profileIv } from "../../../helper/UserInitialvalue";
import { profileValidation } from "../../../helper/UserValidation";
import { EventEmitter } from "../../../helper/EventEmitter";
import { errorNotification, successNotification } from "../../../Utils/Notification";


function Profile() {
  const [edit, setEdit] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // 
  const [profilePicture, setProfilePicture] = useState('');

  const userId = localStorage.getItem("userId");

  const saveHandler = async () => {
    if (Object.keys(formik.errors).length === 0) {
      const formData = new FormData();
      // we will send profile picture when it change other wise not
      if (profilePicture != "") {
        formData.append("profile", profilePicture);
      }
      formData.append("firstName", formik.values.firstName);
      formData.append("lastName", formik.values.lastName);
      formData.append("email", formik.values.email);

      const { status, data, message } = await profileUpdatebyId(userId, formData);
      if (status) {
        successNotification(message)
        // we call get userDetails by id here for update
        getUserDetailsHandler();
        setEdit((show) => !show);
      } else {
        errorNotification(message)
      }
    }
  };

  const handleClick = () => {
    setEdit((show) => !show);
  };

  const [file, setFile] = useState();

  function handleChange(e) {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        errorNotification("File size exceeds 2MB. Please select a smaller file.");
      } else {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
        if (allowedTypes.indexOf(file.type) === -1) {
          errorNotification("Invalid file type. Please select a valid image file.");
        } else {
          formik.setValues({
            ...formik.values,
            profile: e.target.files[0],
          });
          setFile(URL.createObjectURL(e.target.files[0]));
          setProfilePicture(e.target.files[0]);
        }
      }
    } else {
      // Handle the case where no file was selected (e.g., user canceled the file selection).
      errorNotification("No file selected");
    }
  }

  useEffect(() => {
    getUserDetailsHandler();
  }, []);

  const handleSubmit = () => { };

  const formik = useFormik({
    initialValues: profileIv,
    validationSchema: profileValidation,
    onSubmit: handleSubmit,
  });

  // get data of User By User Id
  const getUserDetailsHandler = async () => {
    const { data, message, status } = await getUserDataById(userId);
    if (status) {
      formik.setValues({
        ...formik.values,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        profile: data.profile,
      });
      setFile(data.profile);
      localStorage.setItem("profile", data.profile);
      // here we dispacth event when any profile is changed happens
      EventEmitter.dispatch('profileUpdate', true);
      setFirstName(data.firstName);
      setLastName(data.lastName);
    }
  };

  // cancel Handler 
  const cancelHandler = () => {
    setEdit((show) => !show);
    getUserDetailsHandler();
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 20px", lg: "auto" }}
    >
      <Box padding={"30px 0 10px"}>
        <Typography fontSize={"20px"} fontWeight={"400"}>
          My Profile
        </Typography>
      </Box>
      <Box
        margin={"0 0 40px"}
        sx={{
          border: { xs: "unset", sm: "1px solid #E9E9E9" },
          padding: { xs: "0", sm: "30px" },
          borderRadius: "20px",
          display: "flex",
          gap: "20px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            border: "1px solid #E9E9E9",
            padding: { xs: "20px", sm: "30px" },
            borderRadius: "20px",
            width: { xs: "100%", sm: "unset" },
          }}
        >
          <Grid container>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              {!edit ? (
                <Box
                  sx={{
                    width: "110px",
                    height: "110px",
                    background: "#f1f1f1",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file ? (
                    <img
                      src={file}
                      width={110}
                      height={110}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <FaRegUser
                      style={{ fontSize: "50px", color: "#00000091" }}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "110px",
                    height: "110px",
                    background: "#f1f1f1",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {file ? (
                    <img
                      src={file}
                      width={110}
                      height={110}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <FaRegUser
                      style={{ fontSize: "50px", color: "#00000091" }}
                    />
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                      bottom: "14px",
                      right: "0px",
                      borderRadius: "50%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "22px",
                        height: "22px",
                      }}
                    >
                      <MdOutlineModeEditOutline
                        style={{
                          position: "relative",
                          cursor: "pointer",
                          background: "#000",
                          color: "#fff",
                          fontSize: "22px",
                          padding: "4px",
                          borderRadius: "50%",
                        }}
                      />
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={handleChange}
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          opacity: "0",
                          width: "100%",
                          height: "100%",
                          right: "0",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              marginTop={2}
            >
              <Typography fontSize={"20px"} fontWeight={700}>
                {firstName} {lastName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            border: "1px solid #E9E9E9",
            padding: { xs: "20px 25px", sm: "30px" },
            borderRadius: "20px",
            width: "100%",
          }}
        >
          <Grid container gap={3}>
            <Grid item xs={12}>
              <Grid container gap={3}>
                <Grid item xs={12} sm={5.3} md={4.5}>
                  <CommonInput
                    variant="standard"
                    labelInput
                    labelColor="#8a8a8a"
                    labal="First Name"
                    placeholder="First Name"
                    name="firstName"
                    inputProps={{ maxLength: 20 }}
                    formik={formik}
                    in
                    disabled={edit ? false : true}
                  />
                </Grid>
                <Grid item xs={12} sm={5.3} md={4.5}>
                  <CommonInput
                    variant="standard"
                    labelInput
                    labelColor="#8a8a8a"
                    name="lastName"
                    labal="Last Name"
                    placeholder="Last Name"
                    inputProps={{ maxLength: 20 }}
                    formik={formik}
                    disabled={edit ? false : true}
                  />
                </Grid>
                <Grid item xs={12} sm={5.3} md={4.5}>
                  <CommonInput
                    variant="standard"
                    labelInput
                    labal="Email"
                    labelColor="#8a8a8a"
                    placeholder="Email"
                    name="email"
                    formik={formik}
                    disabled={edit ? false : true}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {edit ? (
                <Stack gap={2} flexDirection={"row"}>
                  <CommonButton buttonName="Save" onClick={saveHandler} />
                  <CommonButton
                    buttonName="Cancel"
                    variant="outlined"
                    onClick={cancelHandler}
                  />
                </Stack>
              ) : (
                <CommonButton buttonName="Edit" onClick={handleClick} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
