import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import { Chip, TableCell, TableRow } from '@mui/material';

export const useStyles = makeStyles(({ theme }) => ({
  cardContainer: {
    borderRadius: '15px !important',
    background: '#FFF',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
    border: "1.5px solid #fff",
  },
  sticky: {
    position: "sticky",
    left: 0,
    zIndex: 9,
    background: "white",
    // boxShadow: "2px 2px 2px #a4a4a4",
    // borderRight: "1px solid black"
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#80BD9E',
      secondary: '#F98866',
    },
    orange: {
      main: '#F98866',
    },
    white: {
      main: '#ffffff',
    },
    gray: {
      main: '#f1f1f1',
      secondary: '#A4A4A4',
      border: '#E9E9E9',
    },
    button: {
      main: '#e3e3e3',
    },
  },
  components: {
    // input ------------
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            padding: '10px 15px',
            border: "1.5px solid #000",
            borderRadius: '6px',
            input: {
              padding: '0'
            }
          },
          '& .Mui-focused': {
            border: "1.5px solid #80BD9E",
            // outline: "1.5px solid #80BD9E",
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'transparent !important',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "unset !important",
          '& .Mui-focused': {
            border: "unset !important",
            outline: "unset !important",
          },
        },
      },
    },
    // input ------------
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '10px 15px',
          borderRadius: '6px',
        },
      },
    },
    // searchbar ------------
    MuiStack: {
      styleOverrides: {
        root: {
          '& .search_field': {
            '& .MuiInputBase-root': {
              background: '#f1f1f1',
              boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
              padding: '8px 15px',
              border: "1.5px solid #fff",
              borderRadius: '6px',
              fontSize: { xs: '12px', sm: '16px' },
              input: {
                padding: '0'
              }
            },
            '& .Mui-focused': {
              border: "1.5px solid #fff",
              outline: "1.5px solid #f1f1f1",
            },
          },
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: { xs: '0 8px', sm: '2px 8px' }
        }
      }
    },
    // searchbar ------------
    // button -----------
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: '6px',
          padding: { xs: '8px 18px', sm: '10px 20px' },
          textTransform: 'capitalize',
          fontSize: { xs: '13px', sm: '18px' },
          color: '#fff',
          boxShadow: 'unset !important',
          lineHeight: '1.55'
        },
        outlined: {
          borderRadius: '6px',
          padding: { xs: '8px 18px', sm: '10px 20px' },
          textTransform: 'capitalize',
          fontSize: { xs: '13px', sm: '18px' },
          boxShadow: 'unset !important',
          color: '#80BD9E',
          lineHeight: '1.55'
        },
      },
    },
    // MuiButton:{
    //   styleOverrides: {
    //     outlined: {
    //       borderRadius: '6px',
    //       padding: '10px 20px',
    //       textTransform: 'capitalize',
    //       fontSize: { xs: '15px', sm: '18px' },
    //       // width: 'unset',
    //       boxShadow:'unset !important',
    //       color: '#80BD9E',
    //     },
    //   },
    // },
    // button -----------
    // table -----------
    MuiTable: {
      styleOverrides: {
        root: {
          '& .device_table_img': {
            background: '#f1f1f1',
            padding: '13px',
            borderRadius: '50%',
          }
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '10px 15px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          textAlign: 'center',
          '&:first-child': {
            textAlign: 'start',
          },
        },
      },
    },
    // table -----------

    MuiGrid: {
      styleOverrides: {
        item: {
          padding: '0 !important',
        },
      },
    },
  },
});

// table -----------
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(even)": {
  //   backgroundColor: "#e5e5e500",
  // },
  // "&:nth-of-type(odd)": {
  //   backgroundColor: "#0f0f0f0a",
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: 0,
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#80BD9E",
  fontWeight: '700',
}));

export const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

export const getStatus = (value) => {
  const status = {
    false: {
      title: "Pending",
      styles: {
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
      },
    },
    true: {
      title: "Active",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
    },

  };
  return status[value];
};

export const getAlertStatus = (value) => {
  const status = {
    false: {
      title: "No",
      styles: {
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
      },
    },
    true: {
      title: "Yes",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
    },

  };
  return status[value];
};
// table -----------
