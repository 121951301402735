import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import {
  Logo,
  LogoWhite,
  ProfileImage,
  User,
  ChangePassword,
  Logout,
  LogoOrange,
} from "../../helper/Constants";
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HistoryIcon from "@mui/icons-material/History";
import LogoutIcon from "@mui/icons-material/Logout";
import CommonModal from "../common/Modal";
import { EventEmitter } from "../../helper/EventEmitter";
const drawerWidth = 240;
const navItems = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Products",
    url: "/product",
  },
  {
    name: "Brands",
    url: "/brands",
  },
  {
    name: "Ingredients",
    url: "/ingredients",
  },
  {
    name: "Comparison",
    url: "/comparison",
  },
  {
    name: 'Upload products',
    url: '/upload-product',
  },
];

function Header(props) {
  const { window } = props;
  const Navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  let userLogin = localStorage.getItem("userId");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // we will receive call of profile when it will update
  EventEmitter.subscribe('profileUpdate', res => {
    const userProfile = localStorage.getItem("profile");
    setProfilePicture(userProfile ? userProfile : ProfileImage)
  })

  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    currentComponent: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [myprofile, setMyProfile] = React.useState(null);
  const [profilePicture, setProfilePicture] = React.useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  // we will setPicture when page is mounting 
  React.useEffect(() => {
    const userProfile = localStorage.getItem("profile")
    setProfilePicture(userProfile ? userProfile : ProfileImage)
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <img src={Logo} width={120}/> */}
      {/* <Divider /> */}
      <List>
        {navItems.map((data, i) => (
          <ListItem
            key={i}
            disablePadding
            onClick={() => Navigate(`${data.url}`)}
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={data.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar component="nav">
        <Toolbar>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            alignItems={'center'}
          >
            {/* <img
              src={LogoOrange}
              width={'170px'}
              style={{ margin: "auto 0", cursor: "pointer" }}
              onClick={() => {
                Navigate("/");
              }}
              alt="logo"
            /> */}
            <Typography fontSize={{ xs: '18px', sm: '25px' }} lineHeight={1.7} className="Abril_Fatface" onClick={() => Navigate('/')}
              sx={{ cursor: 'pointer', width: 'fit-content', textWrap: 'nowrap', whiteSpace: 'nowrap', color: '#fff', background: '#F98866', p: { xs: '3px 15px', sm: '0 15px' }, borderRadius: '7px' }} >Hide n Find</Typography>
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                {navItems.map((data, i) => (
                  <Button
                    key={i}
                    sx={{
                      color: "#fff",
                      fontWeight: "400",
                      padding: "2px 10px",
                      height: "30px",
                      background: `${location.pathname == data.url ? "#F98866" : ""
                        }`,
                      "&:hover": {
                        background: (theme) => theme.palette.primary.secondary,
                      },
                    }}
                    onClick={() => Navigate(`${data.url}`)}
                  >
                    {data.name}
                  </Button>
                ))}
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "flex" },
                  // width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "15px",
                  // margin: "0 10px",
                  padding: { xs: "0 10px", md: "0" },
                }}
              >
                {!userLogin && (
                  <Button
                    sx={{
                      color: "#fff",
                      fontWeight: "400",
                      padding: "2px 10px",
                      height: "30px",
                      // background: "#F98866",
                      // "&:hover": {
                      //   background: (theme) => theme.palette.primary.secondary,
                      // },
                    }}
                    onClick={() => Navigate(`/login`)}
                  >
                    login
                  </Button>
                )}
              </Box>
            </Box>

            <Stack flexDirection={"row"}>
              {userLogin && (
                <>
                  <Box
                    sx={{
                      padding: { xs: "0 10px", sm: "0 20px" },
                      cursor: "pointer",
                    }}
                    onClick={handleClickProfile}
                  >
                    <img
                      src={profilePicture}
                      style={{
                        borderRadius: "50%",
                        objectFit: "cover",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </Box>
                  <Menu
                    anchorEl={myprofile}
                    open={Boolean(myprofile)}
                    onClose={handleCloseProfile}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        Navigate("/profile");
                      }}
                      onMouseUp={handleCloseProfile}
                    >
                      <Stack flexDirection={"row"} gap={"10px"}>
                        <PersonOutlineIcon width="16px" height="16px" />
                        <div style={{ display: "flex" }}>My Profile</div>
                      </Stack>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        Navigate("/favourites");
                      }}
                      onMouseUp={handleCloseProfile}
                    >
                      <Stack flexDirection={"row"} gap={"10px"}>
                        <FavoriteBorderIcon width="16px" height="16px" />
                        <div style={{ display: "flex" }}>Favourites</div>
                      </Stack>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        Navigate("/history");
                      }}
                      onMouseUp={handleCloseProfile}
                    >
                      <Stack flexDirection={"row"} gap={"10px"}>
                        <HistoryIcon width="16px" height="16px" />
                        <div style={{ display: "flex" }}>History</div>
                      </Stack>
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        setIsModalOpen({
                          open: true,
                          currentComponent: "logout",
                        })
                      }
                      onMouseUp={handleCloseProfile}
                    >
                      <Stack flexDirection={"row"} gap={"10px"}>
                        <LogoutIcon width="16px" height="16px" />
                        <div style={{ display: "flex" }}>Logout</div>
                      </Stack>
                    </MenuItem>
                  </Menu>
                </>
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { md: "none" } }}
              >
                <MenuIcon sx={{ color: "#fff", fontSize: "28px" }} />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar >
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
}

export default Header;
