import * as Yup from "yup";

export const registerUserValidation = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      "Password must be 6-16 characters long and contain at least one number and one special character (!@#$%^&*)"
    ),
});

export const loginUserValidation = Yup.object({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const reportErrorValidation = Yup.object({
  product: Yup.string().required("Product  is required"),
  message: Yup.string().required("Message is required"),
  email: Yup.string().nullable()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
})

export const forgotPasswordValidation = Yup.object({
  email: Yup.string().required("Email is required")
});

export const resetPasswordValidation = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      "Password must be 6-16 characters long and contain at least one number and one special character (!@#$%^&*)"
    ),
  confrimPassword: Yup.string()
    .required("ConfrimPassword is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      "confrimPassword must be 6-16 characters long and contain at least one number and one special character (!@#$%^&*)"
    )
});

export const profileValidation = Yup.object({
  firstName: Yup.string().required("FirstName is required"),
  lastName: Yup.string().required("LastName is required"),
  email: Yup.string().matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    "Invalid email format"
  ).required("Email is required"),
  profile: Yup.string().required("Profile is required"),
});

export const productUploadValidation = Yup.object({
  // need to keep this code commented 
  // productImage : Yup.string().required("Image is required"),
  brand: Yup.string().required("Brand is required"),
  category: Yup.string().required("Category is required"),
  productName: Yup.string().required("ProductName is required"),
  subCategory: Yup.string().required("SubCategory is required"),
});
