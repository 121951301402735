import { Box } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../../../components/common/SearchBar";
import Section from "../Home/Section";
import AddProduct from "./AddProduct";
import { EmptyProduct } from "../../../helper/Constants";
import { useNavigate } from "react-router-dom";

const UploadProduct = () => {
  const navigate = useNavigate();
  const [addProduct, setAddProduct] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [masterSearchText, setMasterSearchText] = useState("");

  const handleUploadProduct = () => {
    setAddProduct(!addProduct);
    setEmpty(false);
  };

  // Master Search Handler

  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat,
          category: ""
        },
      });
    }
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 12px", sm: '0 20px', lg: "auto" }}
    >
      {/* as per client feedback remove this section */}

      {/* <Box padding={{ xs: '15px 0', sm: "30px 0 10px" }} width={"100%"}>
        <SearchBar
          placeholder="What are you looking for?"
          searchHandler={masterSearchHandler}
          onClick={masterSearchClickHandler}
        />
      </Box> */}
      {empty && (
        <Box padding={"30px 0 10px"}>
          <Section
            heading="Opps! It’s Empty Here"
            para1="Please add products to compare "
            button="Upload Products"
            onClick={handleUploadProduct}
            image={EmptyProduct}
          />
        </Box>
      )}

      {addProduct && <AddProduct />}
    </Box>
  );
};

export default UploadProduct;
