import React, { useEffect } from "react";
import { Like, Unlike } from "../../../helper/Constants";
import { Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import { addToFavourite, removeFromFavourite } from "../../../Service/Product";
import {
  errorNotification,
  successNotification,
} from "../../../Utils/Notification";
import { slugNameHandler } from "../../../helper/Helper";

const ProductCard = (props) => {
  const userLogin = localStorage.getItem("userId");
  const {
    image,
    name,
    details,
    deleteIcon,
    brand,
    id,
    callParent,
    favouriteStatus,
    noIcon,
  } = props;
  const [favourite, setFavourite] = React.useState(favouriteStatus);
  const handleClick = () => setFavourite((show) => !show);
  const navigate = useNavigate();
  const location = useLocation();

  // const slugName = name;

  const slugName = slugNameHandler(name);

  useEffect(() => {
    setFavourite(favouriteStatus);
  }, [favouriteStatus]);

  const favouriteHandler = async (e) => {
    e.stopPropagation();
    if (!userLogin) {
      navigate("/login");
      return;
    }
    const payload = {
      productId: id,
      userId: userLogin,
    };
    const { data, message, status } = await addToFavourite(payload);
    if (status) {
      successNotification(message);
      setFavourite((show) => !show);
    } else {
      errorNotification(message);
    }
  };

  const unFavouriteHandler = async (e) => {
    e.stopPropagation();
    if (!userLogin) {
      navigate("/login");
      return;
    }
    const payload = {
      productId: id,
      userId: userLogin,
    };
    const { data, message, status } = await removeFromFavourite(payload);
    if (status) {
      setFavourite((show) => !show);
      successNotification(message);
      if (deleteIcon) {
        callParent();
      }
    } else {
      errorNotification(message);
    }
  };

  return (
    <Box
      onClick={() => {
        navigate(`/product/${id}/${slugName}`);
        // navigate("/product-details", {
        //   state: {
        //     productId: id,
        //   },
        // });
      }}
      sx={{
        border: location.pathname === '/comparison' ? '' : "1px solid #E9E9E9",
        padding: { xs: location.pathname === '/comparison' ? '12px 0 0' : "25px 12px 12px", sm: "30px 15px 18px" },
        borderRadius: "20px",
        position: "relative",
        cursor: "pointer",
        textAlign: location.pathname === '/comparison' ? 'center' : "unset",
      }}
      width={"100%"}
      height={"100%"}
    >
      <Box textAlign={"center"}>
        <img src={image} className="product_image" />
      </Box>
      <Typography
        marginBottom={1}
        lineHeight={"23px"}
        fontSize={{
          xs: "15px",
          sm: "18px",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          color: "#000",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
        fontWeight={400}
      >
        {name}
      </Typography>
      <Typography
        lineHeight={"20px"}
        fontSize={{
          xs: "12px",
          sm: "15px",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
        fontWeight={400}
        sx={{ color: (theme) => theme.palette.gray.secondary }}
      >
        {brand?.brandName}
      </Typography>

      {!noIcon && (
        <Box
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: "12px",
            right: "11px",
          }}
        >
          {deleteIcon ? (
            <DeleteIcon
              sx={{ color: "#cdcccc", width: "18px", height: "18px" }}
              onClick={(event) => unFavouriteHandler(event)}
            />
          ) : favourite ? (
            <img src={Like} onClick={(event) => unFavouriteHandler(event)} />
          ) : (
            <img src={Unlike} onClick={(event) => favouriteHandler(event)} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProductCard;
