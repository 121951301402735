// initialvalue of register user Side
export const registerUserIv = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
}

// initial value of login user Side
export const loginUserIv = {
  email: '',
  password: ''
}

// intial value of forgotPassword 
export const forgotPasswordIv = {
  email: ''
}

// intial value of profile 
export const profileIv = {
  firstName: "",
  lastName: "",
  email: "",
  profile: "",

}

export const resetPasswordIv = {
  password: '',
  confrimPassword: '',
}

// For upload Product
export const uploadProductIv = {
  productImage: '',
  brand: '',
  category: '',
  productName: '',
  subCategory: ''
}

export const reportErrorIv = {
  user: "",
  product: "",
  message: "",
  email: ""
}