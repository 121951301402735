import { axiosInstance } from "./helper/AxiosInstance";
import axios from "axios";
// import AxiosWithoutToken from "./helper/AxiosWithoutToken";
import { useSelector } from "react-redux";

export const post = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data);
        if (response && response.data && response.data.status) {
            return {
                status: true,
                data: response?.data?.data || [],
                message: response?.data?.message,
            }
        } else {
            return {
                status: false,
                data: response?.data || [],
                message: response?.data?.message,
            }
        }
    } catch (error) {
        return {
            status: false,
            data: error?.response?.data || [],
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }
};

// pass the data in get (backend requerment)
export const get = async (url,data={}) => {
    try {
        const response = await axiosInstance.get(url,{params: data});
        if (response && response.data && response.data.status) {
            return {
                status: true,
                data: response?.data?.data || [],
                message: response?.data?.message,
            }
        }
        else {
            return {
                status: false,
                data: response?.data || [],
                message: response?.data?.message,
            }
        }
    }
    catch (error) {
        return {
            status: false,
            data: error?.response?.data || [],
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }
};

export const put = async (url, data) => {
    try {
        let header;

        const response = await axiosInstance.put(url, data);
        if (response && response.data && response.data.status) {
            return {
                status: true,
                data: response?.data?.data || [],
                message: response?.data?.message,
            }
        }
        else {
            return {
                status: false,
                data: response?.data || [],
                message: response?.data?.message,
            }
        }
    } catch (error) {
        return {
            status: false,
            data: error?.response?.data || [],
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }
}

export const deleteRequest = async (url,data) => {
    try {
        const response = await axiosInstance.delete(url,{data});
        if (response && response.data && response.data.status) {
            return {
                status: true,
                data: response?.data?.data || [],
                message: response?.data?.message,
            }
        }
        else {
            return {
                status: false,
                data: response?.data || [],
                message: response?.data?.message,
            }
        }
    } catch (error) {
        return {
            status: false,
            data: error?.response?.data || [],
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }

}

export const patch = async (url, data) => {
    try {
        const response = await axiosInstance.patch(url, data);
        if (response && response.data && response.data.status) {
            return {
                status: true,
                data: response?.data?.data || [],
                message: response?.data?.message,
            }
        }
        else {
            return {
                status: false,
                data: response?.data || [],
                message: response?.data?.message,
            }
        }
    } catch (error) {
        return {
            status: false,
            data: error?.response?.data || [],
            message: error?.response?.data?.message || 'Something went wrong',
        }
    }
}