import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonButton from "../../../components/common/Button";
import { Product1 } from "../../../helper/Constants";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../../../components/common/SearchBar";
import { historyProductDeleteHandler, userHistoryById } from "../../../Service/User/History.service";
import { useNavigate } from "react-router-dom";
import { errorNotification, successNotification } from "../../../Utils/Notification";
import { slugNameHandler } from "../../../helper/Helper";

const History = () => {
  const navigate = useNavigate();
  // user Id get from localstorage
  const userLogin = localStorage.getItem("userId");

  // To store Data of history
  const [HistoryData, setHistoryData] = useState([]);

  // Master Search
  const [masterSearchText, setMasterSearchText] = useState("");

  useEffect(() => {
    userHistoryHandler();
  }, []);

  const userHistoryHandler = async () => {
    const { data, message, status } = await userHistoryById(userLogin);
    if (status) {
      setHistoryData(data);
    } else {
      setHistoryData([]);
    }
  };


  const productDeleteHandler = async (id) => {
    const { data, message, status } = await historyProductDeleteHandler(id);
    if (status) {
      successNotification(message);
      userHistoryHandler()
    } else {
      errorNotification(message)
    }
  }

  const productDetailsHandler = (id, name) => {
    const slugName = slugNameHandler(name);
    navigate(`/product/${id}/${slugName}`)
    //   navigate("/product-details",{
    //     state:{
    //         productId :id
    //     }
    //  });
  }

  // Master Search
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  // when user click on search button
  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat
          category: ""
        },
      });
    }
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 20px", lg: "auto" }}
    >
      <Box padding={"30px 0 10px"} width={"100%"}>
        <SearchBar
          placeholder="What are you looking for? "
          searchHandler={masterSearchHandler}
          onClick={masterSearchClickHandler}
        />
      </Box>
      <Box padding={"30px 0 10px"}>
        <Typography fontSize={"20px"} fontWeight={"400"}>
          History
        </Typography>
      </Box>
      <Box margin={"0 0 40px"}>
        {
          HistoryData.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No data found
            </Typography>
          ) : (
            <Grid container gap={2}>
              {HistoryData.map((res) => {
                // const slugName = "";
                return (
                  <Grid item xs={12}>
                    <Stack
                      flexDirection={{ xs: "column", sm: "row" }}
                      gap={{ xs: 2, sm: 3 }}
                      sx={{
                        border: "1px solid #E9E9E9",
                        borderRadius: "20px",
                        padding: "30px 20px",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <img src={res?.product?.productImage && res?.product?.productImage[0]} style={{ width: '200px' }} />
                      <Stack alignItems={"flex-start"} gap={"18px"}>
                        <Box>
                          <Typography
                            fontSize={"15px"}
                            lineHeight={"25px"}
                            fontWeight={"400"}
                          >
                            {res?.product?.productName}
                          </Typography>
                          {/* <Typography
                          fontSize={"15px"}
                          lineHeight={"25px"}
                          fontWeight={"400"}
                        >
                          PERFUME 122 ML Deodorant Spray - For Men & Women (122
                          ml, Pack of 2) Body Spray
                        </Typography> */}
                          {/* <Typography
                          fontSize={"15px"}
                          lineHeight={"25px"}
                          fontWeight={"400"}
                        >
                          - for Men & Women (244 mL)
                        </Typography> */}
                        </Box>
                        <CommonButton buttonName="View Details" onClick={() => productDetailsHandler(res?.product?._id, res?.product?.productName)} />
                      </Stack>
                      <DeleteIcon
                        sx={{
                          position: "absolute",
                          top: "15px",
                          right: "15px",
                          cursor: "pointer",
                          color: "#cdcccc",
                        }}
                        onClick={() => productDeleteHandler(res._id)}
                      />
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          )
        }
      </Box>
    </Box>
  );
};

export default History;
