import React, { useEffect, useState } from "react";
import Review from "./Review";
import { Box, Divider, Pagination, Stack, Typography } from "@mui/material";
import { AlcoholDenat, Product1, Oil } from "../../../../helper/Constants";
import ProductCard from "../ProductCard";
import SearchBar from "../../../../components/common/SearchBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFunctionDetailsByName, ingredientDetails } from "../../../../Service/IngredientService";
// import {
//   getFunctionDetailsByName,
//   ingredientDetails,
// } from "../../../../Service/IngredientService ";

const Ingredients = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [ingredientData, setIngredientData] = useState([]);
  const [ingredientOrigin, setIngredientOrigin] = useState("");

  const [masterSearchText, setMasterSearchText] = useState("");
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    if (state?.ingredientId) {
      ingredientDetailsHandler(state?.ingredientId);
      setIngredientOrigin(state?.origin);
    }
  }, []);

  const ingredientDetailsHandler = async (id) => {
    const { data, message, status } = await ingredientDetails(id);
    if (status) {
      setIngredientData(data.ingredient);
      setProductData(data?.productDatas);
      setIngredientOrigin(data?.ingredient?.ingredientOrigin)
    } else {
      setIngredientData([]);
    }
  };

  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat
          category: "",
        },
      });
    }
  };

  const functionHandler = async (res) => {
    const payload = {
      functionName: res,
    };
    const { data, message, status } = await getFunctionDetailsByName(payload);
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 20px", lg: "auto" }}
    >
      <Box padding={"30px 0"} width={"100%"}>
        <SearchBar
          placeholder="What are you looking for? "
          searchHandler={masterSearchHandler}
          onClick={masterSearchClickHandler}
        />
      </Box>
      <Box
        marginBottom={4}
        sx={{
          border: { xs: "unset", sm: "1px solid #E9E9E9" },
          padding: { xs: "unset", sm: "40px 25px" },
          borderRadius: "20px",
        }}
      >
        <Box sx={{ maxWidth: "950px", margin: "auto" }}>
          <Stack
            flexDirection={{ xs: "column", md: "row" }}
            gap={{ xs: 2, md: 5 }}
            alignItems={"center"}
            marginBottom={3}
          >
            <Box>
              <img src={ingredientOrigin === "Natural" ? AlcoholDenat : Oil} />
            </Box>
            <Box>
              <Typography
                fontSize={{ xs: "22px", sm: "30px" }}
                fontWeight={500}
                color={
                  ingredientOrigin === "Natural"
                    ? "#80BD9E"
                    : ingredientOrigin === "Synthetic"
                      ? "#FF7575"
                      : ingredientOrigin === "Uncertain"
                        ? "#FDD068"
                        : "#FDD068"
                }
              >
                {ingredientData.ingredientName
                  ? ingredientData.ingredientName
                  : "-"}
              </Typography>
              <Typography
                fontSize={{ xs: "16px", sm: "20px" }}
                fontWeight={400}
                lineHeight={{ xs: "25px", sm: "32px" }}
              >
                Ingredient Origin :{" "}
                <Link
                  style={{
                    color:
                      ingredientOrigin === "Natural"
                        ? "#80BD9E"
                        : ingredientOrigin === "Synthetic"
                          ? "#FF7575"
                          : ingredientOrigin === "Uncertain"
                            ? "#FDD068"
                            : "#FDD068",
                    textDecoration: "underline",
                  }}
                >
                  {ingredientData?.ingredientOrigin}
                </Link>
              </Typography>
              <Typography
                fontSize={{ xs: "16px", sm: "20px" }}
                fontWeight={400}
                lineHeight={{ xs: "25px", sm: "32px" }}
              >
                Ingredient Function :{" "}
                {ingredientData?.ingredientFunctions?.map((res) => {
                  return (
                    <Link
                      onClick={() => functionHandler(res)}
                      style={{
                        color:
                          ingredientOrigin === "Natural"
                            ? "#80BD9E"
                            : ingredientOrigin === "Synthetic"
                              ? "#FF7575"
                              : ingredientOrigin === "Uncertain"
                                ? "#FDD068"
                                : "#FDD068",
                        textDecoration: "underline",
                      }}
                    >
                      {res ? res : "-"},
                    </Link>
                  );
                })}
              </Typography>
            </Box>
          </Stack>
        </Box>
        {
          ingredientData.description &&
          <Box
            sx={{
              background: "#F2F8F5",
              borderRadius: "20px",
              maxWidth: "950px",
              margin: "auto",
            }}
          >
            <Typography
              padding={{ xs: "10px 16px", sm: "15px 25px" }}
              fontSize={{ xs: "16px", sm: "22px" }}
              fontWeight={"400"}
              color={"#80BD9E"}
            >
              Description
            </Typography>
            <Divider />
            <Box padding={{ xs: 2, sm: 3 }}>
              <Typography
                fontSize={{ xs: "13px", sm: "17px" }}
                fontWeight={"400"}
                color={"#959595"}
              >
                {ingredientData.description}
              </Typography>
            </Box>
          </Box>
        }
      </Box>
      {ingredientData.researchandReview && ingredientData.researchandReview.length > 0 && (
        <Review data={ingredientData.researchandReview} />
      )}

      {
        productData && productData.length > 0 &&
        <Box marginTop={4}>
          <Stack>
            <Typography
              fontSize={"22px"}
              fontWeight={"500"}
              marginBottom={"10px"}
            >
              {`Products using ${ingredientData.ingredientName ? ingredientData.ingredientName : "-"}`}
            </Typography>
            <Box
              sx={{
                border: { xs: "unset", sm: "1px solid #E9E9E9" },
                padding: { xs: "unset", sm: "30px 25px" },
                borderRadius: "20px",
              }}
            >
              <div className="grid_container">
                {productData.map((res) => {
                  const status = res.flag === 1 ? true : false;
                  return (
                    <div className="grid_item">
                      <ProductCard
                        image={res.productImage && res.productImage[0]}
                        name={res.productName}
                        id={res._id}
                        brand={res.brand}
                        favouriteStatus={status}
                        details={res.nutritionalInformation}
                      />
                    </div>
                  );
                })}
              </div>
            </Box>
            {/* <Box sx={{ padding: "20px 0 30px" }}>
              <Pagination count={3} variant="outlined" shape="rounded" />
            </Box> */}
          </Stack>
        </Box>
      }
    </Box>
  );
};

export default Ingredients;
