import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const TransparencyList = [{ name: "Level 1" }];
const getTransparencyData = (transparencyData) => {
  if (transparencyData === "Level 1") {
    return "Full disclosure of ingredients with details of additives, colours, fragrances, preservatives";
  } else if (transparencyData === "Level 2") {
    return "Only disclosure of ingredients with no details of additives, colours, fragrances, preservatives";
  } else if (transparencyData === "Level 3") {
    return "Only disclosure of key ingredients";
  } else {
    return "No data found";
  }
};

const Transparency = ({ transparencyData }) => {
  const data = getTransparencyData(transparencyData);

  return (
    <>
      {transparencyData && (
        <>
          <Typography fontSize={"20px"} fontWeight={"400"} marginBottom={"5px"}>
            Transparency
          </Typography>
          <Box
            margin={"0 0 30px"}
            sx={{
              border: "1px solid #E9E9E9",
              padding: { xs: "15px", sm: "10px 20px" },
              borderRadius: "20px",
              position: "relative",
            }}
            width={"100%"}
          >
            <Stack
              flexDirection={"row"}
              gap={{ xs: "10px", sm: 2 }}
              flexWrap={"wrap"}
            >
              {/* {TransparencyList.map((data, i) => { */}
              {/* return ( */}
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
                sx={{ padding: "15px" }}
              >
                <Box>{transparencyData}</Box>
                <Tooltip title={data}>
                  <InfoIcon sx={{ width: "20px", height: "20px",cursor:'pointer' }} />
                </Tooltip>
              </Box>
              {/* ); */}
              {/* })} */}
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};

export default Transparency;
