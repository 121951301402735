import { Box, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBar from "../../../components/common/SearchBar";
import {
  cosmeticBrandListHandler,
  foodBrandsHandler,
} from "../../../Service/BrandService";
import { useNavigate } from "react-router-dom";

const Brand = () => {
  const navigate = useNavigate();
  // Food Data
  const [foodData, setFoodData] = useState([]);
  const [foodcurrentPp, setFoodCurrentPp] = useState(1);
  const [foodPerPage, setFoodPerPage] = useState("");
  const [totalFoodData, setTotalFoodData] = useState("");
  const [foodSearchText, setFoodSearchText] = useState("");

  // cosmetic Data
  const [cosmeticData, setCosmeticData] = useState([]);
  const [cosmeticPerpage, setCosmeticPerPage] = useState("");
  const [cosmeticCurrentPp, setCosmeticCurrentPp] = useState(1);
  const [totalCosmeticData, setTotalCosmeticData] = useState("");
  const [consmeticText, setConsmeticText] = useState("");

  // master Search
  const [masterSearchText, setMasterSearchText] = useState("");

  useEffect(() => {
    foodDatahandler(foodcurrentPp, foodSearchText);
    cosmeticDataHandler(cosmeticCurrentPp, consmeticText);
  }, []);

  const foodDatahandler = async (page, search) => {
    const { data } = await foodBrandsHandler(page, search);
    setFoodData(data.brand);
    setFoodPerPage(data.perPageSize);
    setTotalFoodData(data.total);
  };

  const cosmeticDataHandler = async (page, search) => {
    const { data } = await cosmeticBrandListHandler(page, search);
    setCosmeticData(data?.brand);
    setCosmeticPerPage(data?.perPageSize);
    setTotalCosmeticData(data?.total);
  };

  const foodPaginationHandler = (event, newPage) => {
    setFoodCurrentPp(newPage);
    foodDatahandler(newPage, foodSearchText);
  };

  const cosmeticPaginationHandler = (event, newPage) => {
    setCosmeticCurrentPp(newPage);
    cosmeticDataHandler(newPage, consmeticText);
  };

  // Food Search Handler

  const foodSearchHandler = (event) => {
    const inputValue = event.target.value;
    setFoodSearchText(inputValue);
    if (inputValue === "") {
      foodDatahandler(foodcurrentPp, inputValue);
    }
  };

  const foodClickHandler = () => {
    if (foodSearchText.trim() !== "") {
      foodDatahandler(foodcurrentPp, foodSearchText);
    }
  };

  // cosmetic Search Handler
  const cosmeticSearchHandler = (event) => {
    const inputValue = event.target.value;
    setConsmeticText(inputValue);
    if (inputValue === "") {
      cosmeticDataHandler(cosmeticCurrentPp, inputValue);
    }
  };

  const CosmeticClickHandler = () => {
    if (consmeticText.trim() !== "") {
      cosmeticDataHandler(cosmeticCurrentPp, consmeticText);
    }
  };

  // master Search handler
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat
          category: ""
        },
      });
    }
  };

  const brandListHandler = (id, name) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: "",
        brand: id,
        // set product Name null if your not search from Product name
        productName: null,
        ProductText: name,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: ""
      },
    });
  };


  const consmeticListHandler = (id, name) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: "",
        brand: id,
        // set product Name null if your not search from Product name
        productName: null,
        ProductText: name,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: ""
      },
    });
  }

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 12px", sm: '0 20px', lg: "auto" }}
    >
      {/* <Box padding={{ xs: '15px 0 0', sm: "30px 0 10px" }} width={"100%"}>
        <SearchBar
          placeholder="What are you looking for? "
          searchHandler={masterSearchHandler}
          onClick={masterSearchClickHandler}
        />
      </Box> */}
      <Box>
        <Box padding={{ xs: '15px 0', sm: "30px 0" }}>
          <Typography
            fontSize={{ xs: "16px" }}
            fontWeight={"400"}
            marginBottom={"10px"}
          >{`${totalFoodData ? totalFoodData : 0} Food brands found`}</Typography>
          <SearchBar
            margin="unset"
            placeholder="Search for a package food brand"
            searchHandler={foodSearchHandler}
            onClick={foodClickHandler}
          />
        </Box>
      </Box>
      <Box margin={"0 0 10px"} width={"100%"}>
        <div className="grid_container">
          {foodData?.length === 0 ? (
            <Typography variant="body1">
              Data not found for Food brands.
            </Typography>
          ) : (
            foodData?.map((data, i) => (
              <div key={i}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #E9E9E9",
                    borderRadius: { xs: "15px", sm: "20px" },
                    padding: { xs: "10px", sm: "15px" },
                    cursor: "pointer",
                    textAlign: 'center',
                    fontSize: { xs: "14px", sm: "16px" },
                    height: { xs: '70px', sm: '100px' },
                  }}
                  onClick={() => brandListHandler(data._id, data.brandName)}
                >
                  {data.brandName}
                </Box>
              </div>
            ))
          )}
        </div>
      </Box>
      <Box padding={{ xs: '15px 0', sm: "30px 0" }}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={Math.ceil(totalFoodData / foodPerPage)}
          page={foodcurrentPp}
          onChange={foodPaginationHandler}
        />
      </Box>

      <Box>
        <Box padding={"10px 0 30px"}>
          <Typography
            fontSize={{ xs: "16px" }}
            fontWeight={"400"}
            marginBottom={"10px"}
          >{`${totalCosmeticData ? totalCosmeticData : 0}  Cosmetics brands found`}</Typography>
          <SearchBar
            margin="unset"
            placeholder="Search for cosmetics brand"
            searchHandler={cosmeticSearchHandler}
            onClick={CosmeticClickHandler}
          />
        </Box>
      </Box>
      <Box margin={"0 0 10px"} width={"100%"}>
        <div className="grid_container">
          {cosmeticData?.length === 0 ? (
            <Typography variant="body1">
              Data not found for Cosmetic brands.
            </Typography>
          ) : (
            cosmeticData?.map((data, i) => (
              <div key={i}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #E9E9E9",
                    borderRadius: { xs: "15px", sm: "20px" },
                    padding: { xs: "10px", sm: "15px" },
                    cursor: "pointer",
                    textAlign: 'center',
                    fontSize: { xs: "14px", sm: "16px" },
                    height: { xs: '70px', sm: '100px' },
                  }}
                  onClick={() => consmeticListHandler(data._id, data.brandName)}
                >
                  {data.brandName}
                </Box>
              </div>
            ))
          )}
        </div>
      </Box>
      <Box sx={{ padding: "10px 0 30px" }}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={Math.ceil(totalCosmeticData / cosmeticPerpage)}
          page={cosmeticCurrentPp}
          onChange={cosmeticPaginationHandler}
        />
      </Box>
    </Box>
  );
};

export default Brand;
