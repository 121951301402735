import axiosRequest from "../axios/axios";

//get User List
export const getUserList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`userList/${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user Delete

export const DeleteUser = async (id) => {
  try {
    const response = await axiosRequest.delete(`userDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//user details

export const DetailsUser = async (id) => {
  try {
    const response = await axiosRequest.get(`userDetail/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const StatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`userStatusUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const UserUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`adminUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};
