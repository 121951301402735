import React, { useEffect, useState } from "react";
import ProductDetails from "../ProductDetails";
import Ingredients from "../Ingredients";
import Transparency from "../Transparency";
import Hazard from "../Hazard";
import Others from "../Others";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import SearchBar from "../../../../components/common/SearchBar";
import { productApiHandler } from "../../../../Service/Product";
import { useLocation, useNavigate } from "react-router-dom";

const CosmeticDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // Master product Details State
  const [productDetails, setProductDetails] = useState([]);
  // Below state For The Product data
  const [productImage, setProductImage] = useState("");
  const [productName, setProductName] = useState("");
  const [naturalPercentage, setNaturalPercentage] = useState("");
  const [syntheticPercentage, setSyntheticPercentage] = useState("");
  const [unCertainPercentage, setUnCertainPercentage] = useState("");
  const [naturalCount, setNaturalCount] = useState("");
  const [syntheticCount, setSyntheticCount] = useState("");
  const [unCertainCount, setUncertainCount] = useState("");
  // product Id
  const [productId, setProductId] = useState("");
  const [productCategoryName, setProductCategoryName] = useState("");
  const [favouriteFlag, setFavouriteFlag] = useState("");
  // Below State For the ingredients Data
  const [ingredientsData, setIngredientsData] = useState([]);
  // Below State For the Transparency Data
  const [transparencyData, setTransparencyData] = useState([]);
  // Below State For the Hazard Data
  const [hazardData, setHazardData] = useState([]);
  // masterSearch
  const [brandName, setBrandName] = useState("");
  const [masterSearchText, setMasterSearchText] = useState("");

  useEffect(() => {
    productDetailsHandler(state?.productId);
  }, [state]);

  const productDetailsHandler = async (id) => {
    const userToken = localStorage.getItem("userToken");
    const payload = {
      token: userToken
    }
    const { data, message, status } = await productApiHandler(id, payload);
    if (status) {
      setProductDetails(data);
      setProductId(data?.product._id);
      setFavouriteFlag(data.flag);
      setBrandName(data?.brand?.brandName);
      setProductName(data?.product?.productName)
      setProductImage(data?.product?.productImage && data?.product?.productImage[0]);
      setNaturalPercentage(data?.NaturalPercentage);
      setSyntheticPercentage(data?.SyntheticPercentage);
      setUnCertainPercentage(data?.UncertainPercentage)
      setProductCategoryName(data?.category?.masterCategoryName);
      setNaturalCount(data?.NaturalCount)
      setSyntheticCount(data?.SyntheticCount)
      setUncertainCount(data?.UncertainCount)
      // Below State For the ingredients Data
      setIngredientsData(data?.ingredients);
      // Below State For the Transparency Data
      setTransparencyData(data?.product?.transparency);
      // Below State For the Hazard Data
      setHazardData(data?.ingredientHazard);
    } else {
      setProductDetails([]);
    }
  };

  // master Search handler
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat,
          category: ""
        },
      });
    }
  };

  return (
    <>
      <Box
        maxWidth={{ lg: "1160px", xl: "1500px" }}
        margin={{ xs: "0 20px", lg: "auto" }}
      >
        <Box padding={"30px 0"} width={"100%"}>
          <SearchBar
            placeholder="What are you looking for? "
            searchHandler={masterSearchHandler}
            onClick={masterSearchClickHandler}
          />
        </Box>
        <ProductDetails
          productImage={productImage}
          productName={productName}
          productDetails={productDetails}
          NaturalPercentage={naturalPercentage}
          SyntheticPercentage={syntheticPercentage}
          UncertainPercentage={unCertainPercentage}
          NaturalCount={naturalCount}
          SyntheticCount={syntheticCount}
          UncertainCount={unCertainCount}
          brandName={brandName}
          // res.flag === 1 ? true : false;
          favouriteFlag={favouriteFlag === 1 ? true : false}
          productId={productId}
          productCategoryName={productCategoryName}
        />
        <Ingredients ingredientsData={ingredientsData} />
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          gap={{ xs: "0", md: 3 }}
        >
          <Box width={"100%"}>
            <Hazard hazardData={hazardData} />
          </Box>
          <Box width={"100%"}>
            <Transparency transparencyData={transparencyData} />
          </Box>
        </Stack>
        {/* <Others />
        <Box sx={{ padding: "0 0 30px" }}>
          <Pagination count={3} variant="outlined" shape="rounded" />
        </Box> */}
        <Stack sx={{ justifyContent: 'flex-end' }}>
          <Typography>Report Error</Typography>
        </Stack>
      </Box>
    </>
  );
};

export default CosmeticDetails;
