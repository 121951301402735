import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Hazard = ({ hazardData }) => {

  return (
    <>
      {hazardData.length > 1 && (
        <>
          <Typography fontSize={"20px"} fontWeight={"400"} marginBottom={"5px"}>
            Hazard
          </Typography>
          <Box
            margin={"0 0 30px"}
            sx={{
              border: "1px solid #E9E9E9",
              padding: { xs: "15px", sm: "30px 20px" },
              borderRadius: "20px",
              position: "relative",
            }}
            width={"100%"}
          >
            <Grid
              container
              gap={{ xs: "10px 0", sm: 2 }}
              justifyContent={"space-evenly"}
            >
              {hazardData.map((res) => {
                return (
                  <Grid item xs={12} md={12} lg={5.8}>
                    <Accordion sx={{ border: "1px solid #E9E9E9", boxShadow: 'unset !important' }}>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          gap={1}
                          width={'100%'}
                          sx={{
                            // padding: "15px",
                            // borderRadius: "10px",
                            background: "#fff",
                          }}
                        >

                          <Box>{res?.name}</Box>
                          <Box> {res?.count}</Box>
                          {/* <ExpandMoreIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          background: "#80BD9E",
                          color: "#fff",
                          borderRadius: "4px",
                        }}
                      /> */}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack flexDirection={'row'} gap={1} flexWrap={'wrap'}>
                          {res?.ingredientList.map((data, i) => {
                            return (
                              <Box key={i} sx={{ p: '10px 16px', background: '#80BD9E30', color: '#80BD9E', borderRadius: '5px' }}>{data}</Box>
                            )
                          })}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>

                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Hazard;
