import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from "react";
import CommonButton from '../../../components/common/Button'
import { useNavigate } from 'react-router-dom'

const Categories = (props) => {
  const { CategorieList } = props
  const navigate = useNavigate();
  const [displayCount, setDisplayCount] = useState(10);
  const increment = 10;
  const seeMoreHandler = () => {
    setDisplayCount((prevCount) => prevCount + increment);
  };
  if (CategorieList.length === 0) {
    return (
      <Box
        margin={'10px 0 30px'}
        sx={{
          border: '1px solid #E9E9E9',
          padding: { xs: '20px', sm: '30px' },
          borderRadius: '20px',
          position: 'relative',
        }}
        width={'100%'}
      >
        <Typography variant="subtitle1" align="center">
          Data not found.
        </Typography>
      </Box>
    );
  };

  const navigateHandler = (data) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: data._id,
        brand: "",
        // set product Name null if your not search from Product name 
        productName: null,
        ProductText:data.categoryName,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: ""
      }
    })
  }

  return (
    <Box margin={{ xs: '0 0 15px', sm: "0 0 30px" }} sx={{ border: '1px solid #E9E9E9', padding: { xs: '12px', sm: '30px' }, borderRadius: '20px', position: 'relative' }} width={'100%'}>
      <Stack flexDirection={'row'} gap={{ xs: 1, sm: 2 }} flexWrap={'wrap'}>
        {
          CategorieList.slice(0, displayCount).map((data, i) => {
            return (
              <div key={i}>
                <CommonButton buttonName={data.categoryName} buttonColor='gray' onClick={() => navigateHandler(data)} className='categoriesButton'
                  style={{ padding: { xs: '3px 6px', sm: '14px 30px' }, fontSize: { xs: '10px', sm: '16px' } }}
                />
              </div>
            )
          })
        }
      </Stack>
      {displayCount < CategorieList.length && (
          <Box textAlign={"center"} mt={2}>
            <CommonButton
              size="small"
              buttonName="see more"
              style={{ padding: "7px 20px" }}
              onClick={() => seeMoreHandler()}
            />
          </Box>
        )}
    </Box>
  )
}

export default Categories