import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LogIn, Logo, Google, Facebook } from "../../../helper/Constants";
import CommonInput from "../../../components/common/Input";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../../components/common/Button";
import { useFormik } from "formik";
import { loginUserIv } from "../../../helper/UserInitialvalue";
import { loginUserValidation } from "../../../helper/UserValidation";
import { userLoginHandler } from "../../../Service/User/Login.service";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  faceBookApiHandler,
  googleApiHandler,
} from "../../../Service/Comman.service";
import {
  errorNotification,
  successNotification,
} from "../../../Utils/Notification";
import Cookies from "universal-cookie";

function Login() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const userEmail = cookies.get("userEmail");
    const userPassword = cookies.get("userPassword");
    if (userEmail && userPassword) {
      setRememberMe(true);
      formik.setValues({
        ...formik.values,
        email: userEmail,
        password: userPassword,
      });
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data, message, status } = await userLoginHandler(formik.values);
      if (status) {
        // Handle success
        localStorage.setItem("userId", data._id);
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("profile", data.profile);

        if (rememberMe) {
          cookies.set("userEmail", formik.values.email);
          cookies.set("userPassword", formik.values.password);
        } else {
          cookies.remove("userEmail");
          cookies.remove("userPassword");
        }
        successNotification(message);
        navigate(`/`);
      } else {
        errorNotification(message);
      }
    } catch (error) {
      // Handle API request error here
      errorNotification("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: loginUserIv,
    validationSchema: loginUserValidation,
    onSubmit: handleSubmit,
  });

  const googleHandler = async (Data) => {
    const payload = {
      email: Data.email,
      firstName: Data.given_name,
      lastName: Data.family_name,
      profile: Data.picture,
      googleToken: Data.access_token,
    };
    const { data, message, status } = await googleApiHandler(payload);
    if (status) {
      successNotification(message);
      navigate(`/`);
      localStorage.setItem("userId", data._id);
      localStorage.setItem("userToken", data.googleToken);
    } else {
      errorNotification(message);
    }
  };

  const faceBookHandler = async (fbData) => {
    const payload = {
      email: fbData.email,
      firstName: fbData.given_name,
      lastName: fbData.family_name,
      profile: fbData.picture,
      googleToken: fbData.accessToken,
    };
    const { data, message, status } = await faceBookApiHandler(payload);
    if (status) {
      successNotification(message);
      navigate(`/`);
      localStorage.setItem("userId", data._id);
      localStorage.setItem("userToken", data.googleToken);
    } else {
      errorNotification(message);
    }
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const navigateHandler = () => {
    navigate(`/`);
  }

  return (
    <Box sx={{ maxHeight: "1000px", maxWidth: "1440px", margin: "auto" }}>
      <Box padding={"10px 0 0 20px"}>
        {/* <img src={Logo} width={150} onClick={() => navigateHandler()} /> */}
        <Typography fontSize={{ xs: '18px', sm: '25px' }} lineHeight={1.7} className="Abril_Fatface" onClick={() => navigateHandler()}
          sx={{ cursor: 'pointer', width: 'fit-content', textWrap: 'nowrap', color: '#fff', background: '#F98866', p: { xs: '3px 15px', sm: '0 15px' }, borderRadius: '7px' }} >Hide n Find</Typography>
      </Box>
      <Box sx={{ margin: { xs: "20px", sm: "0" } }}>
        <Grid
          container
          gap={3}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "calc(100vh - 110px)" }}
        >
          <Grid
            item
            xs={12}
            md={5.5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img src={LogIn} style={{ width: "100%", height: "100%" }} />
          </Grid>
          <Grid item xs={12} md={5.5} sx={{}}>
            <Grid
              container
              gap={3}
              sx={{
                border: "1px solid #a4a4a4",
                maxWidth: "500px",
                padding: { xs: "40px 25px", sm: "60px 50px 70px 50px" },
                borderRadius: "25px",
                margin: "auto",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  fontSize={"24px"}
                  fontWeight={"400"}
                  marginBottom={"2px"}
                  className="Abril_Fatface"
                >
                  Log In
                </Typography>
                <Typography
                  fontSize={{ xs: "14px", sm: "18px" }}
                  marginBottom={2}
                >
                  New User?{" "}
                  <Link to="/signup" style={{ color: "#80BD9E" }}>
                    Start your Journey
                  </Link>{" "}
                  with us!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="Email"
                  name="email"
                  formik={formik}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="Password"
                  passwordInput
                  name="password"
                  formik={formik}
                  inputProps={{ maxLength: 16 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Grid item xs={6}>
                    <Grid container sx={{ alignItems: "center", gap: "3px" }}>
                      <Grid item sx={6}>
                        <Checkbox
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                          sx={{
                            padding: "0",
                            color: "#80BD9E",
                            "& .MuiSvgIcon-root": { fontSize: 25 },
                            "&.Mui-checked": {
                              color: "#80BD9E",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sx={6}>
                        <Typography fontSize={"14px"}>Remember me</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={6}>
                    <Link to="/forgot-password">
                      <Typography fontSize={"14px"}>
                        Forget Password?
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CommonButton
                  buttonName="Login"
                  fullWidth
                  // if you want to pass loader funcanlity than pass loader true
                  loader={true}
                  // here we will pass loading Status
                  loading={loading}
                  onClick={() => !loading && formik.handleSubmit()}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={5.8}>
                    <LoginSocialGoogle
                      isOnlyGetToken={false}
                      client_id={
                        "731912302097-ikj4otsde46jobbdg6e6lle7lc99p36i.apps.googleusercontent.com"
                      }
                      scope={"email profile"}
                      onResolve={({ provider, data }) => {
                        googleHandler(data);
                      }}
                      onReject={(provider, error) => {
                        errorNotification(error);
                      }}
                    >
                      <CommonButton
                        icon={Google}
                        buttonName="Google"
                        fullWidth
                        style={{
                          background: "#fff",
                          color: "#000",
                          border: "1px solid #D1D1D1",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          "&:active": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                      />
                    </LoginSocialGoogle>
                  </Grid>
                  <Grid item xs={5.8}>
                    <LoginSocialFacebook
                      isOnlyGetToken={false}
                      appId={"282319871368611"}
                      onResolve={(responsive) => {
                        faceBookHandler(responsive.data);
                      }}
                      onReject={(err) => {
                        errorNotification(err);
                      }}
                    >
                      <CommonButton
                        icon={Facebook}
                        buttonName="Facebook"
                        fullWidth
                        style={{
                          background: "#fff",
                          color: "#000",
                          border: "1px solid #D1D1D1",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          "&:active": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                      />
                    </LoginSocialFacebook>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Login;
