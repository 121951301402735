import { get, post, patch, put, deleteRequest } from "../web.request";


// For the Google Login 
export const googleApiHandler = (data) => {
    return post(`/userLoginWithGoogle`, data)
}

// For the FaceBook Login 
export const faceBookApiHandler = (data) => {
    return post(`/userLoginWithFacebook`, data)
}

export const reportErrorSubmit = (data) => {
    return post(`/addProductReport`, data);
}