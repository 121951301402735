import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../components/common/Button";

const Brands = (props) => {
  const { BrandList } = props;
  const navigate = useNavigate();
  const [visibleBrand, setVisibleBrand] = useState(10);
  const [totalBrandList, setTotalBrandList] = useState([]);

  useEffect(() => {
    setTotalBrandList(BrandList);
  }, [BrandList]);

  if (totalBrandList.length === 0) {
    return (
      <Box
        margin={"10px 0 30px"}
        sx={{
          border: "1px solid #E9E9E9",
          padding: { xs: "20px", sm: "30px" },
          borderRadius: "20px",
          position: "relative",
        }}
        width={"100%"}
      >
        <Typography variant="subtitle1" align="center">
          Data not found.
        </Typography>
      </Box>
    );
  }

  const BrowseAllHandler = () => {
    setVisibleBrand(visibleBrand + 10);
  };

  const navigateHandler = (data) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: "",
        brand: data._id,
        // set product Name null if your not search from Product name
        productName: null,
        ProductText: data.brandName,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: ""
      },
    });
  };

  return (
    <Box
      margin={{ xs: '0 0 15px', sm: "0 0 30px" }}
      sx={{
        border: "1px solid #E9E9E9",
        padding: { xs: "10px", sm: "30px" },
        borderRadius: "20px",
        position: "relative",
      }}
      width={"100%"}
    >
      <div className="grid_container">
        {totalBrandList.slice(0, visibleBrand).map((data, i) => {
          return (
            <div key={i} onClick={() => navigateHandler(data)}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #E9E9E9",
                  borderRadius: { xs: "15px", sm: "20px" },
                  padding: { xs: "10px", sm: "15px" },
                  cursor: "pointer",
                  textAlign: 'center',
                  fontSize: { xs: "14px", sm: "16px" },
                  height: { xs: '70px', sm: '100px' },
                }}
              >
                {data.brandName}
              </Box>
            </div>
          );
        })}
      </div>
      {visibleBrand < totalBrandList.length && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: { xs: "12px", sm: "30px" },
          }}
        >
          <CommonButton
            buttonName="Browse all brands"
            onClick={BrowseAllHandler}
          />
        </Box>
      )}
    </Box>
  );
};

export default Brands;
