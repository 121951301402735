import React, { useEffect, useState } from "react";
import ProductBannerCard from "./ProductBannerCard";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import SearchBar from "../../../components/common/SearchBar";
import Cosmetic from "./cosmetic/Cosmetic";
import Food from "./food/Food";
import ProductCard from "./ProductCard";
import { Product1 } from "../../../helper/Constants";
import {
  masterCategoryList,
  masterProductSearchHandler,
} from "../../../Service/Product";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/loader/Loader";

const Product = () => {
  const navigate = useNavigate();
  const [cosCtgSearch, setCosCtgSearch] = useState(true);
  const [cosBrdSearch, setCosBrdSearch] = useState(true);
  const [products, setProducts] = useState(false);
  const [masterSearch, setMasterSearch] = useState("");
  const [productTextData, setProductTextData] = useState("");
  const [loading, setLoading] = useState(false);


  // MasterCategorey List will be store in this useState
  const [masterCategoreyList, setMasterCategoreyList] = useState([]);

  // To Fetch MasterCategorey List while Page load
  useEffect(() => {
    masterCategoreyListHandler();
  }, []);

  // To Fetch MasterCategorey List
  const masterCategoreyListHandler = async () => {
    try {
      setLoading(true);
      const { data } = await masterCategoryList();
      setMasterCategoreyList(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Master Search Handler
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearch(inputValue);
    // need to keep this Code
    // if(inputValue === ""){
    //   masterTextHandler(inputValue);
    // }
  };

  // Click Master Handler
  const clickMasterSearchHandler = () => {
    if (masterSearch.trim() != "") {
      navigate(`/products-search`, {
        state: {
          // subCategory means Category of master categorey
          subCategory: "",
          brand: "",
          productName: masterSearch,
          // here category Means when they click on any master category and wan to show product of then than pass cat
          category: "",
        },
      });
    }
  };

  // here we will receive id of master categorey send to product-search
  const masterCategoreyHandler = (id, name) => {
    navigate(`/products-search`, {
      state: {
        // subCategory means Category
        subCategory: "",
        brand: "",
        // set product Name null if your not search from Product name
        productName: null,
        ProductText: name,
        // here category Means when they click on any master category and wan to show product of then than pass cat
        category: id,
      },
    });
  };
  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 12px", sm: '0 20px', lg: "auto" }}
    >
      {
        loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <Box padding={{ xs: '15px 0 0', sm: "30px 0 10px" }} width={"100%"}>
              <SearchBar
                placeholder="What are you looking for? "
                searchHandler={masterSearchHandler}
                onClick={clickMasterSearchHandler}
              />
            </Box>
            <Box padding={{ xs: '15px 0', sm: "30px 0" }}>
              <ProductBannerCard
                data={masterCategoreyList}
                highliteCard={productTextData}
                masterCategoreyHandler={masterCategoreyHandler}
                setProductTextData={setProductTextData}

              />
            </Box>
            {masterCategoreyList.map((res) => {
              return (
                <Cosmetic
                  cosCtgSearch={cosCtgSearch}
                  cosCtgList={res.categoryDatas.length > 0}
                  catDataList={res.categoryDatas}
                  brdDataList={res.brandDatas}
                  cosBrdSearch={cosBrdSearch}
                  masterId={res._id}
                  cosBrdList={res.brandDatas.length > 0}
                  mCategoreyName={res.masterCategoryName}
                />
              );
            })}
          </>

        )
      }



      {/* here cosmetic means list of Master categorey */}


      {/* This Product means List of Product as */}
      {products && (
        <Box marginTop={2}>
          <Stack>
            <Typography>1529 Results Found</Typography>
            <Box sx={{ padding: "10px 0 20px" }}>
              <div className="grid_container">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => {
                  return (
                    <div className="grid_item">
                      <ProductCard
                        image={Product1}
                        name="Axe Signature Body Spray"
                        details="A breakthrough in shampoo making industry"
                      />
                    </div>
                  );
                })}
              </div>
            </Box>
            <Box sx={{ padding: "0 0 30px" }}>
              <Pagination count={3} variant="outlined" shape="rounded" />
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Product;