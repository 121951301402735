import {
  Dashboard, Dashboard_black,User
} from './Constants';
export const SidebarList = [
    {
      name: 'Dashboard',
      icon: Dashboard,
      activeIcon: Dashboard_black,
      path: '/dashboard'
    },
    {
      name: 'Users',
      icon:User,
      activeIcon: User,
      path: '/users'
    },
  ]

export const forgotPasswordIV  = {
  email: '',
};



export const slugNameHandler = (str) =>{
  return String(str)
  .normalize('NFKD') // split accented characters into their base characters and diacritical marks
  .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
  .trim() // trim leading or trailing whitespace
  .toLowerCase() // convert to lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
  .replace(/\s+/g, '-') // replace spaces with hyphens
  .replace(/-+/g, '-'); // remove consecutive hyphens
}
