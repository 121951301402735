import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { SignUp, Logo, Google, Facebook } from "../../../helper/Constants";
import CommonInput from "../../../components/common/Input";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../../components/common/Button";
import { useFormik } from "formik";
import { registerUserValidation } from "../../../helper/UserValidation";
import { registerUserIv } from "../../../helper/UserInitialvalue";
import { userRegisterHandler } from "../../../Service/User/Register.service";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  faceBookApiHandler,
  googleApiHandler,
} from "../../../Service/Comman.service";
import {
  errorNotification,
  successNotification,
} from "../../../Utils/Notification";

function Signup() {
  const navigate = useNavigate();
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data, message, status } = await userRegisterHandler(
        formik.values
      );
      if (status) {
        successNotification(message);
        navigate(`/`);
      } else {
        errorNotification(message);
      }
    } catch (error) {
      errorNotification("An error occurred during signup.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: registerUserIv,
    validationSchema: registerUserValidation,
    onSubmit: handleSubmit,
  });

  const loginWithGoogle = async (Data) => {
    const payload = {
      email: Data?.email,
      googleToken: Data?.access_token,
    };
    const { data, message, status } = await googleApiHandler(payload);
    if (status) {
      successNotification(message);
      navigate(`/`);
      localStorage.setItem("userId", data._id);
      localStorage.setItem("userToken", data.googleToken);
    } else {
      errorNotification(message);
    }
  };

  const loginWithFaceBook = async (fbData) => {
    const payload = {
      email: fbData.email,
      googleToken: fbData.accessToken,
    };
    const { data, message, status } = await faceBookApiHandler(payload);
    if (status) {
      successNotification(message);
      localStorage.setItem("userId", data._id);
      localStorage.setItem("userToken", data.googleToken);
      navigate(`/`);
    } else {
      errorNotification(message);
    }
  };

  const navigateHandler = () => {
    navigate(`/`);
  }

  return (
    <Box sx={{ maxHeight: "1000px", maxWidth: "1440px", margin: "auto" }}>
      <Box padding={"10px 0 0 20px"}>
        {/* <img src={Logo} width={150} onClick={()=>navigateHandler()}/> */}
        <Typography fontSize={{ xs: '18px', sm: '25px' }} lineHeight={1.7} className="Abril_Fatface" onClick={() => navigateHandler()}
          sx={{ cursor: 'pointer', width: 'fit-content', textWrap: 'nowrap', color: '#fff', background: '#F98866', p: { xs: '3px 15px', sm: '0 15px' }, borderRadius: '7px' }} >Hide n Find</Typography>
      </Box>
      <Box sx={{ margin: { xs: "20px", sm: "" } }} height={"100%"}>
        <Grid
          container
          gap={3}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ margin: { sm: "0 0 50px 0" } }}
          height={{ xs: "calc(100vh - 130px)" }}
        >
          <Grid
            item
            xs={12}
            md={5.5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <img src={SignUp} style={{ width: "100%", height: "100%" }} />
          </Grid>
          <Grid item xs={12} md={5.5} sx={{}}>
            <Grid
              container
              gap={3}
              sx={{
                border: "1px solid #a4a4a4",
                maxWidth: "500px",
                padding: { xs: "40px 25px", sm: "60px 50px 70px 50px" },
                borderRadius: "25px",
                margin: "auto",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  fontSize={"24px"}
                  fontWeight={"400"}
                  marginBottom={"2px"}
                  className="Abril_Fatface"
                >
                  Sign Up
                </Typography>
                <Typography
                  fontSize={{ xs: "14px", sm: "18px" }}
                  marginBottom={2}
                >
                  Already have an Account?{" "}
                  <Link to="/login" style={{ color: "#80BD9E" }}>
                    Log In
                  </Link>{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="First Name"
                  name="firstName"
                  formik={formik}
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="Last Name"
                  name="lastName"
                  formik={formik}
                  inputProps={{ maxLength: 15 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="Email"
                  name="email"
                  formik={formik}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonInput
                  placeholder="Password"
                  name="password"
                  formik={formik}
                  passwordInput
                  inputProps={{ maxLength: 16 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonButton
                  buttonName="Sign Up"
                  fullWidth
                  // if you want to pass loader funcanlity than pass loader true
                  loader={true}
                  // here we will pass loading Status
                  loading={loading}
                  onClick={() => !loading && formik.handleSubmit()}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={5.8}>
                    <LoginSocialGoogle
                      isOnlyGetToken={false}
                      client_id={
                        "731912302097-ikj4otsde46jobbdg6e6lle7lc99p36i.apps.googleusercontent.com"
                      }
                      onResolve={({ provider, data }) => {
                        setProvider(provider);
                        setProfile(data);
                        loginWithGoogle(data);
                      }}
                      onReject={(err) => {
                        errorNotification(err);
                      }}
                    >
                      <CommonButton
                        icon={Google}
                        buttonName="Google"
                        fullWidth
                        style={{
                          background: "#fff",
                          color: "#000",
                          border: "1px solid #D1D1D1",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          "&:active": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                      />
                    </LoginSocialGoogle>
                  </Grid>
                  <Grid item xs={5.8}>
                    <LoginSocialFacebook
                      isOnlyGetToken={false}
                      appId={"282319871368611"}
                      onResolve={(responsive) => {
                        loginWithFaceBook(responsive.data);
                      }}
                      onReject={(err) => {
                        errorNotification(err);
                      }}
                    >
                      <CommonButton
                        icon={Facebook}
                        buttonName="Facebook"
                        fullWidth
                        style={{
                          background: "#fff",
                          color: "#000",
                          border: "1px solid #D1D1D1",
                          "&:hover": {
                            backgroundColor: "#ffffff",
                          },
                          "&:active": {
                            backgroundColor: "#ffffff",
                          },
                        }}
                      />
                    </LoginSocialFacebook>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Signup;
