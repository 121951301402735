import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../../components/common/Button'
import {DeleteUser } from "../../state/action/usersAction";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const DeleteRecord = ({ handleClose,DeletesetId }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default severity

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
      //delete
    const handleDelete = async () => {
        const res = await DeleteUser(DeletesetId);
        if (res && res.status === true) {
        handleClose();
            setSnackbarSeverity('success');
            setSnackbarMessage(res.message);
            setSnackbarOpen(true);
        } else {
            setSnackbarSeverity('error');
            setSnackbarMessage(res.message);
            setSnackbarOpen(true);
        }
    };
    return (
        <>
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
            Delete Record
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography>Are you sure to want to delete this record?</Typography>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='No' onClick={handleClose} />
                <CommonButton buttonName='Yes' onClick={handleDelete} />
            </DialogActions>
        </div>
        <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top", // Change this to 'bottom' if needed
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
        </>
    )
}

export default DeleteRecord