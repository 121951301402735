import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Cosmetic, PackagedFood } from "../../../helper/Constants";

const bannerdata = [
  { image: Cosmetic, name: "Cosmetics" },
  { image: PackagedFood, name: "Packaged Food" },
];
const ProductBannerCard = ({ data, masterCategoreyHandler, highliteCard, setProductTextData }) => {
  const [selectCat, setSelectCat] = useState("");

  if (data.length === 0) {
    return <Typography>No Data Found</Typography>;
  }

  const handleImageClick = (id, textName) => {
    setSelectCat(textName)
    masterCategoreyHandler(id, textName);
  };
  return (
    <Stack
      flexDirection={{ xs: "row", md: "row" }}
      gap={{ xs: '10px', sm: 3 }}
      justifyContent={"center"}
      flexWrap={"wrap"}
    >
      {data.map((data, i) => {
        return (
          <Stack
            key={i}
            flexDirection={"row"}
            alignItems={"center"}
            gap={{ xs: 3, sm: 5 }}
            maxWidth={{ md: "48%", lg: "560px" }}
            justifyContent={"center"}
            sx={{
              border: "1px solid #E9E9E9",
              padding: { xs: "20px", sm: "30px" },
              borderRadius: "20px",
              position: "relative",
              cursor: 'pointer',
              transition: "background-color 0.3s", // Adding a smooth transition for color change
              boxShadow: (highliteCard === data.masterCategoryName) && 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              background: highliteCard === data.masterCategoryName && '#80BD9E20',
              "&:hover": {
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
              },
            }}

            width={"100%"}
            onClick={() => {
              setProductTextData(data.masterCategoryName);
              handleImageClick(data._id, data.masterCategoryName)
            }
            }
          >
            <Box
              sx={{
                width: { xs: "100px", sm: "200px" },
                height: { xs: "100px", sm: "200px" },
              }}
            >
              <img
                src={data.image} alt=""
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={{ xs: "18px", sm: "24px" }} sx={{ display: 'block' }}>
              {data.masterCategoryName}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ProductBannerCard;
