import React, { useEffect, useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CommonButton from '../../components/common/Button'
import { useNavigate } from 'react-router-dom'
import CommonInput from '../../components/common/Input';
import { useFormik } from 'formik';
import { reportErrorSubmit } from '../../Service/Comman.service';
import { errorNotification, successNotification } from '../../Utils/Notification';
import { reportErrorIv } from '../../helper/UserInitialvalue';
import { reportErrorValidation } from '../../helper/UserValidation';

const ReportError = ({ handleClose, modalOpen }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const user = localStorage.getItem("userId");
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const { data, message, status } = await reportErrorSubmit(formik.values);
            if (status) {
                // Handle success
                successNotification(message);

            } else {
                errorNotification(message);
            }
        } catch (error) {
            // Handle API request error here
            errorNotification("An error occurred during login.");
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: reportErrorIv,
        validationSchema: reportErrorValidation,
        onSubmit: handleSubmit,
    });


    useEffect(() => {
        if (modalOpen?.data?.totalData?.produName) {
            getDetails(modalOpen?.data?.totalData?.produName, modalOpen?.data?.totalData?.productId)

        }
    }, []);


    const getDetails = (data, id) => {
        formik.setFieldValue('product', id)
        formik.setFieldValue('user', user)
    };

    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Report Error
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid
                    container
                    gap={2}
                    width={{ xs: "250px", sm: "500px" }}
                    paddingY={1}
                >
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '12px', sm: '14px' }} fontWeight={'600'}> Product : <span style={{ color: '#F98866' }}>{modalOpen?.data?.totalData?.produName}</span></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput
                            name="message"
                            formik={formik}
                            labelInput labal="Please describe the issue. Thanks for your help! <3"
                            multiline rows={3}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput
                            name="email"
                            formik={formik}
                            labelInput labal="Your email address (if you'd like a response)"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='Send' loader={true}
                    loading={loading} onClick={() => formik.handleSubmit()} />
            </DialogActions>
        </div>
    )
}

export default ReportError