import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import CommonButton from '../../../components/common/Button'
import { keyframes } from '@emotion/react';

const moveUpDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
`;

const Section = (props) => {
    const { id, backgroundColor, flexdirection, heading, heading2, para1, para2, para3, buttonName, button, onClick, image, head, orangeButton } = props

    return (
        <Box id={id} sx={{ background: `${backgroundColor ? '#f2f8f5' : '#fff'}`, padding: { xs: '30px 15px', md: '100px 50px' } }}>
            <Stack flexDirection={flexdirection ? { xs: 'column-reverse', md: 'row' } : { xs: 'column-reverse', md: 'row-reverse' }} justifyContent={'center'} alignItems={'center'} gap={{ xs: 3, sm: 5, md: 8 }}>
                <Stack gap={'10px'} alignItems={'flex-start'} width={{ xs: 'unset', sm: 'unset' }}>
                    <Typography fontSize={{ xs: '20px', md: '24px' }} lineHeight={'30px'} fontWeight={400} className='Abril_Fatface'>{heading}</Typography>
                    {heading2 &&
                        <Typography fontSize={{ xs: '20px', md: '24px' }} lineHeight={'16px'} fontWeight={400} marginBottom={'10px'} className='Abril_Fatface'>{heading2}</Typography>
                    }
                    {head &&
                        <Typography fontSize={{ xs: '20px', md: '30px' }} lineHeight={'16px'} fontWeight={400} marginBottom={'10px'}>{head}</Typography>
                    }
                    <Stack marginBottom={1}>
                        <Typography fontSize={{ xs: '12px', md: '15px' }} lineHeight={'20px'} fontWeight={400} sx={{ color: (theme) => theme.palette.gray.secondary }}>{para1}</Typography>
                        <Typography fontSize={{ xs: '12px', md: '15px' }} lineHeight={'20px'} fontWeight={400} sx={{ color: (theme) => theme.palette.gray.secondary }}>{para2}</Typography>
                        {para3 &&
                            <Typography fontSize={{ xs: '12px', md: '15px' }} lineHeight={'20px'} fontWeight={400} sx={{ color: (theme) => theme.palette.gray.secondary }}>{para3}</Typography>
                        }
                    </Stack>
                    {
                        button ?
                            <CommonButton buttonName={button} onClick={onClick} /> :
                            orangeButton ? <CommonButton buttonName={orangeButton} onClick={onClick} buttonColor='orange' className='find_out_button' /> :
                                <Box onClick={onClick} sx={{
                                    cursor: 'pointer', padding: '12px', width: '40px', height: '40px', backgroundColor: 'primary.secondary', borderRadius: '50%', display: 'flex',
                                    '&:hover': {
                                        '& svg': {
                                            animation: `${moveUpDown} 0.6s ease-in-out infinite`,
                                        }
                                    }
                                }}>
                                    <Box sx={{
                                        overflow: 'hidden', display: 'flex',
                                    }}>
                                        {buttonName}
                                    </Box>
                                </Box>
                    }
                </Stack>
                <Box maxWidth={'400px'} maxHeight={'400px'}>
                    <img src={image} style={{ width: '100%', height: '100%' }} alt='' />
                </Box>
            </Stack>
        </Box >
    )
}

export default Section