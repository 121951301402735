import React from "react";
import {
    Box,
    Typography,
} from "@mui/material";
import CommonButton from "../../../../components/common/Button";
import { BsCheck } from 'react-icons/bs';

const Uploadsuccess = ({ handleClose }) => {
    return (
        <Box padding={3} sx={{ width: { xs: '250px', sm: '400px' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '100px', height: '100px', background: '#80BD9E', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginBottom: '20px' }}>
                    <BsCheck style={{ fontSize: '70px', color: '#fff' }} />
                </Box>
            </Box>
            <Typography fontSize={'18px'} fontWeight={'500'} marginBottom={4} textAlign={'center'}>Uploaded Successfully</Typography>
            <Box textAlign={'center'}>
                <CommonButton
                    fullWidth
                    buttonName="Done"
                    onClick={handleClose}
                />
            </Box>
        </Box>
    );
};

export default Uploadsuccess;
