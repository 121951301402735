import React from "react";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../components/common/Button";
import { productSearchHandler } from "../../../Service/ProductCompare";
import { EventEmitter } from "../../../helper/EventEmitter";
import { errorNotification } from "../../../Utils/Notification";
const SearchProduct = ({ handleClose }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [productDetails, setProductDetails] = React.useState([]);
  const [productAdded, setProductAdded] = React.useState([]);



  const searchHandler = async (res) => {
    if (typeof res === "string" && res.trim() !== "" && res.length > 2) {
      const { data, message, status } = await productSearchHandler(res);
      if (status) {
        const productName = data.map((item) => item.productName)
        setOptions(productName);
        setProductDetails(data);
      } else {
        setOptions([]);
      }
    }
  };

  const productAddHandler = () => {
    if (value) {
      const selectedProduct = productDetails.find((product) => product.productName === value);
      if (selectedProduct) {
        if (!productCheckHandler(selectedProduct._id)) {
          // need to keep this console.log
          setProductAdded((productAdded) => [...productAdded, selectedProduct]);
          setProductAdded([...productAdded, selectedProduct]);
          EventEmitter.dispatch('procuctCompare', selectedProduct);
          handleClose();
        } else {
          errorNotification("product Added already")
        }
      }
    }
  };

  const productCheckHandler = (id) => {
    const data = productAdded.some((product) => product._id === id);
    return data;
  };


  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Search Product
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onInputChange={(event) => searchHandler(event.target.value)}
          hiddenLabel
          selectOnFocus
          clearOnBlur
          className="autocomplate_search"
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={options}
          filterOptions={(options) => options}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          sx={{ width: { xs: 250, sm: 300 } }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} hiddenLabel placeholder="Search Product" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <CommonButton buttonName="Ok" disabled={!value} onClick={productAddHandler} />
        <CommonButton
          buttonName="Cancel"
          variant="outlined"
          onClick={handleClose}
        />
      </DialogActions>
    </div>
  );
};

export default SearchProduct;
