import { get, post } from "../../web.request";

export const ingredientDetails = (ingredientId) => {
    return get(`/ingredientProductList/${ingredientId}`);
};


export const getIngredientsFunctionHandler = () => {
    return get(`/userfunctionList`);
}

export const getFunctionDetailsByName = (data) => {
    return post(`/functionDescription`, data)
}

export const getIngredientDetailsBySearch = (searchText, data) => {
    return post(`/functionIngredientList?ingredientName=${searchText}`, data);
}