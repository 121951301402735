import React, { useEffect, useState } from "react";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { productApiHandler } from "../../../Service/Product";
import SearchBar from "../../../components/common/SearchBar";
import Others from "../product-details/Others";
import Hazard from "../product-details/Hazard";
import Transparency from "../product-details/Transparency";
import Ingredients from "../product-details/Ingredients";
import ProductDetails from "../product-details/ProductDetails";
import CommonModal from "../../../components/common/Modal";
import { MdOutlineReportProblem } from "react-icons/md";
import Loader from "../../../components/common/loader/Loader";
import NutritionInfo from "../product-details/NutritionInfo";

const ProductDetailsSlug = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { name } = useParams();
  const navigate = useNavigate();
  // Master product Details State
  const [productDetails, setProductDetails] = useState([]);
  // Below state For The Product data
  const [productImage, setProductImage] = useState("");
  const [productName, setProductName] = useState("");
  const [naturalPercentage, setNaturalPercentage] = useState("");
  const [syntheticPercentage, setSyntheticPercentage] = useState("");
  const [unCertainPercentage, setUnCertainPercentage] = useState("");
  const [naturalCount, setNaturalCount] = useState("");
  const [syntheticCount, setSyntheticCount] = useState("");
  const [unCertainCount, setUncertainCount] = useState("");
  // product Id
  const [productId, setProductId] = useState("");
  const [productCategoryName, setProductCategoryName] = useState("");
  const [productCategoryId, setProductCategoryId] = useState("");
  const [favouriteFlag, setFavouriteFlag] = useState("");
  // Below State For the ingredients Data
  const [ingredientsData, setIngredientsData] = useState([]);
  // Below State For the Transparency Data
  const [transparencyData, setTransparencyData] = useState([]);
  // Below State For the Hazard Data
  const [hazardData, setHazardData] = useState([]);
  // masterSearch
  const [brandName, setBrandName] = useState("");
  const [brandId, setBrandId] = useState("");
  const [nutritionData, setNutritionData] = useState([]);
  const [masterSearchText, setMasterSearchText] = useState("");
  const [dataNotFound, setDataNotFound] = useState(false);
  const [loader, setLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  useEffect(() => {
    productDetailsHandler(id);
  }, [state]);

  const productDetailsHandler = async (id) => {
    try {
      setLoader(true);
      const userToken = localStorage.getItem("userToken");
      const payload = {
        token: userToken,
      };
      const { data, message, status } = await productApiHandler(id, payload);
      if (status) {
        setProductDetails(data);
        setProductId(data?.product._id);
        setFavouriteFlag(data.flag);
        setBrandName(data?.brand?.brandName);
        setBrandId(data?.brand?._id);
        setProductName(data?.product?.productName);
        const nutritionInfo = data?.product.nutritionalInformation
          ? data?.product.nutritionalInformation.split(",")
          : [];
        setNutritionData(nutritionInfo);
        setProductImage(
          data?.product?.productImage && data?.product?.productImage[0]
        );
        setNaturalPercentage(data?.NaturalPercentage);
        setSyntheticPercentage(data?.SyntheticPercentage);
        setUnCertainPercentage(data?.UncertainPercentage);
        setProductCategoryName(data?.category?.masterCategoryName);
        setProductCategoryId(data?.category?._id);
        setNaturalCount(data?.NaturalCount);
        setSyntheticCount(data?.SyntheticCount);
        setUncertainCount(data?.UncertainCount);
        // Below State For the ingredients Data
        setIngredientsData(data?.ingredients);
        // Below State For the Transparency Data
        setTransparencyData(data?.product?.transparency);
        // Below State For the Hazard Data
        setHazardData(data?.ingredientHazard);
      } else {
        setProductDetails([]);
        setDataNotFound(true);
      }
    } catch (error) {
      // Handle the error appropriately, e.g., log it or show a user-friendly message
      console.error("Error in productDetailsHandler:", error);
    } finally {
      setLoader(false);
      // Optional: Perform any cleanup or finalization steps here
      // For example, you may want to perform additional actions or update the UI
    }
  };

  // master Search handler
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat,
          category: "",
        },
      });
    }
  };

  return (
    <>
      <Box
        maxWidth={{ lg: "1160px", xl: "1500px" }}
        margin={{ xs: "0 20px", lg: "auto" }}
      >
        {loader ? (
          <Loader />
        ) : (
          <>
            <Box padding={"30px 0"} width={"100%"}>
              <SearchBar
                placeholder="What are you looking for? "
                searchHandler={masterSearchHandler}
                onClick={masterSearchClickHandler}
              />
            </Box>
            {dataNotFound ? (
              <h2> Data Not Found </h2>
            ) : (
              <>
                <ProductDetails
                  productImage={productImage}
                  productName={productName}
                  productDetails={productDetails}
                  NaturalPercentage={naturalPercentage}
                  SyntheticPercentage={syntheticPercentage}
                  UncertainPercentage={unCertainPercentage}
                  NaturalCount={naturalCount}
                  SyntheticCount={syntheticCount}
                  UncertainCount={unCertainCount}
                  brandName={brandName}
                  brandId={brandId}
                  // res.flag === 1 ? true : false;
                  favouriteFlag={favouriteFlag === 1 ? true : false}
                  productId={productId}
                  productCategoryName={productCategoryName}
                  productCategoryId={productCategoryId}
                />
                <Ingredients ingredientsData={ingredientsData} />
                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  gap={{ xs: "0", md: 3 }}
                >
                  <Box width={"100%"}>
                    <Hazard hazardData={hazardData} />
                  </Box>
                  <Box width={"100%"}>
                    <Transparency transparencyData={transparencyData} />
                  </Box>
                  <Box width={"100%"}>
                    <NutritionInfo nutritionData={nutritionData} />
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    justifyContent: "flex-end",
                    pb: 3,
                    alignItems: "center",
                    gap: "3px",
                    cursor: "pointer",
                  }}
                  flexDirection={"row"}
                >
                  <MdOutlineReportProblem
                    style={{ fontSize: "20px", color: "#F98866" }}
                  />
                  <Typography
                    sx={{ color: "#F98866", fontSize: "18px" }}
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "reportError",
                        data: {
                          totalData: {
                            produName: productName,
                            productId: productId,
                          },
                        },
                      })
                    }
                  >
                    Report Error
                  </Typography>
                </Stack>
              </>
            )}
          </>
        )}
      </Box>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default ProductDetailsSlug;

// export default ProductDetailsSlug
