import React from 'react'
import { Section31, Section32 } from '../../../helper/Constants'
import { Box, Stack, Typography } from '@mui/material'
import { keyframes } from '@emotion/react';

const moveUpDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
`;

const Option = (props) => {
    const { id, buttonName, onClick, } = props

    return (
        <Box id={id} sx={{ background: '#f2f8f5', padding: { xs: '50px 20px', md: '100px 50px' } }} >
            <Stack marginBottom={5} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                <Typography textAlign={'center'} fontSize={'24px'} fontWeight={400} className='Abril_Fatface'>Options are many</Typography>
            </Stack>

            <Stack flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'center'} alignItems={'center'} gap={8}>
                <Box maxWidth={'400px'} maxHeight={'400px'}>
                    <img src={Section31} style={{ width: '100%', height: '100%' }} alt='' />
                    <Typography textAlign={'center'} marginTop={'20px'}>Cosmetics</Typography>
                </Box>
                <Box maxWidth={'400px'} maxHeight={'400px'}>
                    <img src={Section32} style={{ width: '100%', height: '100%' }} alt='' />
                    <Typography textAlign={'center'} marginTop={'20px'}>Packaged Food</Typography>
                </Box>
            </Stack>
            <Stack marginBottom={5} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                <Box onClick={onClick} sx={{
                    cursor: 'pointer', padding: '12px', width: '40px', height: '40px', backgroundColor: 'primary.secondary', borderRadius: '50%', display: 'flex',
                    '&:hover': {
                        '& svg': {
                            animation: `${moveUpDown} 0.6s ease-in-out infinite`,
                        }
                    }
                }}>
                    <Box onClick={onClick} sx={{
                        overflow: 'hidden', display: 'flex',
                    }}>
                        {buttonName}
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}

export default Option