import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from '../action-types/authActionType';


const initialState = {
    user: {},
    client: {},
    isAuthenticate: false,
    token: '',
    permissions: []
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload?.user_data || {},
                isAuthenticate: true,
                permissions: action.payload?.user_permissions || [],
                token: action.payload?.access || ''
            };
        case LOGOUT:
            return {
                ...state,
                user: {},
                client: {},
                isAuthenticate: false,
                permissions: [],
                token: ''
            };
        default:
            return state;
    }
}
