import React from 'react'
import Section from './Section'
import { Section1, Section2, Section4, Section5, Section6, } from '../../../helper/Constants'
import Option from './Option'
import { useNavigate } from 'react-router-dom'
import { Link, animateScroll as scroll } from 'react-scroll';
import { FaArrowDown } from "react-icons/fa";

const Home = () => {
  const navigate = useNavigate()
  const scrollToSection = (sectionId) => {
    scroll.scrollTo(document.getElementById(sectionId).offsetTop, {
      duration: 1400,
      delay: 100,
      smooth: true,
    });
  };

  return (
    <>
      <Section backgroundColor flexdirection
        id="section1"
        heading='What Goes “On” you ?'
        para1='You use a lot of things from head to toe.'
        para2='Are you sure you know what are they ?'
        buttonName={<FaArrowDown style={{ fontSize: '16px', margin: 'auto', color: '#fff' }} />}
        onClick={() => scrollToSection("section2")}
        image={Section1}
      />
      <Section
        id="section2"
        heading='What Goes “In” you ?'
        para1='You eat and drink from those big and small packets.'
        para2=' Do you know what they contain ?'
        buttonName={<FaArrowDown style={{ fontSize: '16px', margin: 'auto', color: '#fff' }} />}
        onClick={() => scrollToSection("section3")}
        image={Section2}
      />
      <Option
        id="section3"
        buttonName={<FaArrowDown style={{ fontSize: '16px', margin: 'auto', color: '#fff' }} />}
        onClick={() => scrollToSection("section4")}
      />
      <Section flexdirection
        id="section4"
        heading='Information is scattered'
        heading2='and selective.'
        para1='The ingredients which are mentioned as "Key" are'
        para2='present in quite less quantity compared to others'
        para3='synthetic ingredients'
        buttonName={<FaArrowDown style={{ fontSize: '16px', margin: 'auto', color: '#fff' }} />}
        onClick={() => scrollToSection("section5")}
        image={Section4}
      />
      <Section backgroundColor
        id="section5"
        heading='Branding is distracting'
        para1='Have you wondered how a product like shampoo'
        para2='can have these few ingredients only ? It shows'
        para3='information is not complete'
        buttonName={<FaArrowDown style={{ fontSize: '16px', margin: 'auto', color: '#fff' }} />}
        onClick={() => scrollToSection("section6")}
        image={Section5}
      />
      <Section flexdirection
        id="section6"
        heading='The world’s first consumer'
        heading2='information powerhouse'
        para1='We got your back.'
        para2='Just enjoy the power we bring to you.'
        orangeButton={'Find Out'}
        onClick={() => navigate("/product")}
        image={Section6}
      />
    </>
  )
}

export default Home