import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ProductCard from "../Product/ProductCard";
import CommonButton from "../../../components/common/Button";
import CommonSelect from "../../../components/common/Select";
import AddIcon from "@mui/icons-material/Add";
import { Product1 } from "../../../helper/Constants";
import CircleIcon from "@mui/icons-material/Circle";
import CommonModal from "../../../components/common/Modal";
import { EventEmitter } from "../../../helper/EventEmitter";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { productCompareHandler } from "../../../Service/ProductCompare";
import {
  errorNotification,
  successNotification,
} from "../../../Utils/Notification";
import CompareResult from "./CompareResult";
import { slugNameHandler } from "../../../helper/Helper";

const AddProduct = ({ handleCompareResult }) => {
  const [totalProductList, setTotalProductList] = useState([]);
  const navigate = useNavigate();
  const [compareFlag, setCompareFlag] = useState(false);
  const [compareData, setCompareData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const productBrand = [{ name: "product1", value: "product1" }];
  const variant = [{ name: "variant", value: "variant" }];

  EventEmitter.subscribe("procuctCompare", (res) => {
    // productCheckHandler
    addProductHandler(res);
    // if(!productCheckHandler(res._id)){
    // setTotalProductList([...totalProductList, res]);
    // }else{
    // errorNotification("product Added already")
    // }
    // productCheckHandler(res._id){}
  });

  const addProductHandler = (res) => {
    setErrorMessage("");
    if (!productCheckHandler(res._id)) {
      setTotalProductList([...totalProductList, res]);
    } else {
      setErrorMessage("product added already")
    }
  }

  const productCheckHandler = (id) => {
    const data = totalProductList.some((product) => product._id === id);
    return data;
  };

  const productAddHandler = async () => {
    const productIds = totalProductList.map((product) => product._id);
    const payload = {
      productIds,
      userId: localStorage.getItem("userId"),
    };
    setLoading(true);
    const { data, message, status } = await productCompareHandler(payload);
    if (status) {
      successNotification(message);
      setCompareFlag(true);
      setLoading(false);
      setCompareData(data);
    } else {
      setLoading(false);
      errorNotification(message);
    }
  };

  const deleteHandler = (event, id) => {
    setErrorMessage("");
    event.stopPropagation();
    const productIndex = totalProductList.findIndex(
      (product) => product._id === id
    );

    if (productIndex !== -1) {
      const updatedProductList = [
        ...totalProductList.slice(0, productIndex),
        ...totalProductList.slice(productIndex + 1),
      ];

      setTotalProductList(updatedProductList);

      successNotification("Product has been deleted successfully.");
    } else {
      errorNotification("Product not found or unable to delete.");
    }
  };

  const [isModalOpen, setIsModalOpen] = React.useState({
    open: false,
    currentComponent: "",
  });
  return (
    <Box margin={"30px 0px"} pt={3} minHeight={'calc(100vh - 400px)'}>
      {
        !compareFlag &&
        <Box
          sx={{
            border: { sm: "1px solid #E9E9E9" },
            padding: { xs: "0 10px", sm: "30px" },
            borderRadius: "20px",
          }}
        >
          <Grid container gap={{ xs: 1, sm: 2 }} justifyContent={"center"}>
            {errorMessage && <div style={{ width: '100%', color: 'red', fontWeight: '600', textAlign: 'center' }}>{errorMessage}</div>}

            {totalProductList &&
              totalProductList.length > 0 &&
              totalProductList.map((res) => {
                const slugName = slugNameHandler(res?.productName)
                return (
                  <Grid item xl={2} lg={2.3} md={2.8} sm={4.5} xs={5.8}>
                    <Box
                      sx={{
                        border: "1px solid #E9E9E9",
                        padding: { xs: "25px 12px 12px", sm: "30px 15px 18px" },
                        borderRadius: "20px",
                        position: "relative",
                        cursor: "pointer",
                      }}
                      width={"100%"}
                      height={"100%"}
                    >
                      <Box
                        textAlign={"center"}
                        onClick={() => {
                          navigate(`/product/${res._id}/${slugName}`)
                        }}
                      >
                        <img
                          src={res.productImage && res.productImage[0] && res.productImage[0]}
                          className="product_image" alt=''
                        />
                      </Box>
                      <Typography
                        mt={2}
                        marginBottom={1}
                        lineHeight={"23px"}
                        fontSize={{
                          xs: "15px",
                          sm: "18px",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          color: "#000",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textAlign: 'center'
                        }}
                        fontWeight={400}
                      >
                        {res.productName}
                      </Typography>
                      {/* <Typography
          lineHeight={"20px"}
          fontSize={{
            xs: "12px",
            sm: "15px",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
          fontWeight={400}
          sx={{ color: (theme) => theme.palette.gray.secondary }}
        >
          {details}{" "}
        </Typography> */}

                      <Box
                        sx={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "12px",
                          right: "11px",
                        }}
                      >
                        <DeleteIcon
                          sx={{ color: "#cdcccc", width: "18px", height: "18px" }}
                          onClick={(event) => deleteHandler(event, res._id)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            {
              totalProductList.length != 4 &&
              <>
                <Grid item xl={2} lg={2.3} md={2.8} sm={4.5} xs={5.8}>
                  <Box
                    onClick={() =>
                      setIsModalOpen({
                        open: true,
                        currentComponent: "searchProduct",
                      })
                    }
                    height={"100%"}
                    sx={{
                      border: "1px solid #E9E9E9",
                      cursor: "pointer",
                      padding: { xs: "20px 12px", sm: "30px 15px" },
                      borderRadius: "20px",
                      position: "relative",
                    }}
                    width={"100%"}
                  >
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"inherit"}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          width: { xs: "60px", sm: "80px" },
                          height: { xs: "60px", sm: "80px" },
                          background: (theme) => theme.palette.primary.main,
                        }}
                      >
                        <AddIcon
                          sx={{
                            width: { xs: "25px", sm: "30px" },
                            height: { xs: "25px", sm: "30px" },
                            color: "#fff",
                          }}
                        />
                      </Box>

                      <Typography
                        fontSize={{ xs: "15px", sm: "18px" }}
                        fontWeight={400}
                        marginTop={1}
                      >
                        Add Product
                      </Typography>
                    </Stack>
                    {/* <Stack marginTop={3} gap={1}>
                    <CommonSelect selectList={productBrand} selectHead='Select Product/Brand' className='compare_select' />
                    <CommonSelect selectList={variant} selectHead='Select Variant' className='compare_select' />
                  </Stack> */}
                  </Box>
                </Grid>
              </>
            }
            {totalProductList.length > 1 && (
              <Grid item xs={12}>
                <Box marginTop={3} textAlign={"center"}>
                  <CommonButton
                    loader={true}
                    loading={loading}
                    disable={loading}
                    buttonName="Compare Now"
                    onClick={productAddHandler}
                    style={{ background: loading && '#e3e3e3 !important' }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      }
      {
        compareFlag && <CompareResult data={compareData} />
      }
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </Box>
  );
};

export default AddProduct;
