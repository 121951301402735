import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import { Logo, Forgotpassword } from "../../../helper/Constants";
import CommonInput from "../../../components/common/Input";
import CommonButton from "../../../components/common/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordIv } from "../../../helper/UserInitialvalue";
import { forgotPasswordValidation } from "../../../helper/UserValidation";
import { forgotPasswordHandler } from "../../../Service/User/ForgotPassword";
import { errorNotification, successNotification } from "../../../Utils/Notification";

function ForgotPassword() {
  const navigate = useNavigate();

  const navigateHandler = () => {
    navigate(`/`);
  }

  const handleSubmit = async () => {
    const payload = {
      email: formik.values.email
    }
    const { data, message, status } = await forgotPasswordHandler(payload);
    if (status) {
      successNotification(message);
    } else {
      errorNotification(message);
    }
  };

  const formik = useFormik({
    initialValues: forgotPasswordIv,
    validationSchema: forgotPasswordValidation,
    onSubmit: handleSubmit
  })

  return (
    <Box sx={{ maxHeight: "1000px", maxWidth: "1440px", margin: "auto" }}>
      <Box padding={"10px 0 0 20px"}>
        {/* <img src={Logo} width={150} onClick={()=>navigateHandler()}/> */}
        <Typography fontSize={{ xs: '18px', sm: '25px' }} lineHeight={1.7} className="Abril_Fatface" onClick={() => navigateHandler()}
          sx={{ cursor: 'pointer', width: 'fit-content', textWrap: 'nowrap', color: '#fff', background: '#F98866', p: { xs: '3px 15px', sm: '0 15px' }, borderRadius: '7px' }} >Hide n Find</Typography>
      </Box>
      <Box sx={{ margin: { xs: "20px", sm: "0" } }}>
        <Grid
          container
          gap={3}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "calc(100vh - 110px)" }}
        >
          <Grid
            item
            xs={12}
            md={5.5}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Box sx={{ maxWidth: "400px", maxHeight: "400px", margin: "auto" }}>
              <img
                src={Forgotpassword}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={5.5} sx={{}}>
            <Grid
              container
              gap={3}
              sx={{
                border: "1px solid #a4a4a4",
                maxWidth: "500px",
                padding: { xs: "40px 25px", sm: "60px 50px 70px 50px" },
                borderRadius: "25px",
                margin: "auto",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  fontSize={"24px"}
                  fontWeight={"400"}
                  marginBottom={"2px"}
                  className="Abril_Fatface"
                >
                  ForgotPassword
                </Typography>
                <Typography
                  fontSize={{ xs: "14px", sm: "16px" }}
                  color={"#A4A4A4"}
                  marginBottom={2}
                >
                  No Worries! Enter your registered email below to receive your
                  password reset instructions{" "}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder="Email" name="email" formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <CommonButton
                  buttonName="Submit"
                  fullWidth
                  onClick={() => formik.handleSubmit()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}

export default ForgotPassword;
