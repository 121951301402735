import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "../Product/ProductCard";
import SearchBar from "../../../components/common/SearchBar";
import { favListHandler } from "../../../Service/Product";
import { useNavigate } from "react-router-dom";

const Wishlist = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [favouriteData, setFavouriteData] = useState([]);

  // master Search
  const [masterSearchText, setMasterSearchText] = useState("");

  // we are handling searchText Here
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };
  //  when user click on search button 
  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat,
          category: ""
        },
      });
    }
  };

  useEffect(() => {
    favouriteProductHandler();
  }, []);

  const favouriteProductHandler = async () => {
    const { data, status, message } = await favListHandler(userId);
    if (status) {
      setFavouriteData(data);
    }
  };

  // when user delete favourite from child component we will update the list of favourite item
  const childrenHandler = () => {
    favouriteProductHandler();
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0 20px", lg: "auto" }}
    >
      <Box padding={"30px 0 10px"} width={"100%"}>
        <SearchBar
          placeholder="What are you looking for? "
          searchHandler={masterSearchHandler}
          onClick={masterSearchClickHandler}
        />
      </Box>
      <Box padding={"30px 0 10px"}>
        <Typography fontSize={"20px"} fontWeight={"400"}>
          Favourites
        </Typography>
      </Box>
      <Box margin={"0 0 40px"}>
        {favouriteData.length === 0 ? (
          <Typography variant="body1">No favorite products found.</Typography>
        ) : (
          <div className="grid_container">
            {favouriteData.map((res) => {
              return (
                <div className="grid_item" key={res?.productId?._id}>
                  <ProductCard
                    deleteIcon
                    id={res?.productId?._id}
                    image={res?.productId?.productImage && res.productId.productImage[0]}
                    callParent={childrenHandler}
                    brand={res?.productId?.brand}
                    name={res?.productId?.productName}
                    details={res?.productId?.nutritionalInformation}
                  />
                </div>
              );
            })}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Wishlist;
