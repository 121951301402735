import React from 'react'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import { Box } from '@mui/material'

const Layout = ({children}) => {

  return (
    <>
      <Header />
      <Box marginTop={{ xs: '57px', sm: '65px' }}>
        {children}
        <Footer />
      </Box>
    </>
  )
}

export default Layout