import { get, post } from "../../web.request";

// To Add product 
export const ProductAddByuser = (data) =>{
    return post(`/userAddProduct`,data);
};

// fetching Categorey List Data 
export const categoryData = () =>{
    return get (`/masterCategoryDropdown`);
};

// fetching subCategorey List
export const subCategoreyData = (id) =>{
    return get(`/categoryDropdown/${id}`);
};

// fetch brand List 
export const brandListHandler = (id) =>{
    return get (`/brandDropdown/${id}`);
}