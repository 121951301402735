import { Box } from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../../../components/common/SearchBar";
import Section from "../Home/Section";
import AddProduct from "./AddProduct";
import { EmptyProduct } from "../../../helper/Constants";
import CompareResult from "./CompareResult";
import { useNavigate } from "react-router-dom";

const CompareProduct = () => {
  const [addProduct, setAddProduct] = useState(false);
  const [result, setResult] = useState(false);
  const [empty, setEmpty] = useState(true);
  const navigate = useNavigate();

  // master Search
  const [masterSearchText, setMasterSearchText] = useState("");

  const handleCompareProduct = () => {
    setAddProduct(!addProduct);
    setEmpty(false);
  };

  const handleCompareResult = () => {
    setAddProduct(!addProduct);
    setResult(true);
  };

  // master Search handler
  const masterSearchHandler = (event) => {
    const inputValue = event.target.value;
    setMasterSearchText(inputValue);
  };

  const masterSearchClickHandler = () => {
    if (masterSearchText.trim() != "") {
      navigate(`/products-search`, {
        state: {
          subCategory: "",
          brand: "",
          productName: masterSearchText,
          // here category Means when they click on any master category and wan to show product of then than pass cat
          category: "",
        },
      });
    }
  };

  return (
    <Box
      maxWidth={{ lg: "1160px", xl: "1500px" }}
      margin={{ xs: "0", sm: '0 20px', lg: "auto" }}
    >
      {/* as per client feedback remove this section */}
      {/* <Box padding={{ xs: '15px 0 0', sm: "30px 0 10px" }} width={"100%"}>
        <SearchBar
          placeholder="What are you looking for?"
          searchHandler={masterSearchHandler}
          onClick={masterSearchClickHandler}
        />
      </Box> */}
      {empty && (
        <Box padding={"30px 0 10px"}>
          <Section
            head="Please add products to compare "
            button="Add Products"
            onClick={handleCompareProduct}
            image={EmptyProduct}
          />
        </Box>
      )}

      {addProduct && <AddProduct handleCompareResult={handleCompareResult} />}
      {result && <CompareResult />}
    </Box>
  );
};

export default CompareProduct;
