import { Box, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LogoWhite, FacebookWhite, InstagramWhite, LinkedinWhite, YoutubeWhite, EmailWhite, PhoneWhite, } from '../../helper/Constants'
import { Link, useNavigate } from 'react-router-dom'

const footerSocialData = [
  {
    icon: FacebookWhite,
    url: '',
  },
  {
    icon: YoutubeWhite,
    url: '',
  },
  {
    icon: LinkedinWhite,
    url: '',
  },
  {
    icon: InstagramWhite,
    url: '',
  },
]

const Footer = () => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <Box sx={{ background: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.white.main }}>
      <Box sx={{ padding: { xs: '32px 20px', sm: '40px 32px' } }}>
        <Stack flexWrap={'wrap'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={{ xs: 3, sm: 5 }}>
          {/* <img src={LogoWhite} width={170} /> */}
          <Typography fontSize={{ xs: '18px', sm: '25px' }} lineHeight={1.7} className="Abril_Fatface" onClick={() => navigate('/')}
            sx={{ mb: 'revert', cursor: 'pointer', width: 'fit-content', textWrap: 'nowrap', whiteSpace: 'nowrap', color: '#fff', background: '#F98866', p: { xs: '3px 15px', sm: '0 15px' }, borderRadius: '7px' }} >Hide n Find</Typography>
          <Stack>
            <Typography lineHeight={'18px'}>Disclaimer</Typography>
            <Divider width='50px' sx={{ borderColor: '#fff' }} />
            <Typography fontSize={'12px'} marginTop={1}>States that information on their site should not be construed as {width >= 600 ? <br /> : ''}
              advice and should not be relied on. Disclaims liability for any{width >= 600 ? <br /> : ''}
              inaccurate statement on their website.</Typography>
          </Stack>
          <Stack justifyContent={'center'} alignItems={'center'} flexDirection={'row'} gap={'10px'}>
            <Link to='' style={{ color: '#fff' }}>
              <Box sx={{ background: (theme) => theme.palette.primary.secondary, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={PhoneWhite} />
              </Box>
            </Link>
            <Typography>Contact Us</Typography>
          </Stack>
          <Stack flexDirection={'row'} gap={'15px'}>
            {
              footerSocialData.map((data, i) => {
                return (
                  <Link key={i} to={`${data.url}`} style={{ color: '#fff' }}>
                    <Box sx={{ background: (theme) => theme.palette.primary.secondary, width: '40px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={data.icon} />
                    </Box>
                  </Link>
                )
              })
            }
          </Stack>
        </Stack>
      </Box>
      <Divider sx={{ background: (theme) => theme.palette.white.main, }} />
      <Box sx={{ padding: { xs: '16px 25px', sm: '20px 38px' } }}>
        <Typography fontSize={'12px'} fontWeight={400}>All Right Reserved</Typography>
      </Box>
    </Box >
  )
}

export default Footer