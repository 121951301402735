import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CommonButton from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
const Logout = ({ handleClose }) => {
  const logoutAdmin = () => {
    localStorage.clear();
    handleClose();
    navigate("/adminlogin");
  };
  const navigate = useNavigate();
  return (
    <div>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Logout
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography>Do you want to logout from this admin? </Typography>
      </DialogContent>
      <DialogActions>
        <CommonButton
          buttonName="Cancel"
          variant="outlined"
          onClick={handleClose}
        />
        <CommonButton buttonName="Logout" onClick={logoutAdmin} />
      </DialogActions>
    </div>
  );
};

export default Logout;
