import axios from "axios";
import { useSelector } from "react-redux";
import { store } from "../state/store";

axios.defaults.withCredentials = false;

const axiosInstance = axios.create({
    // baseURL: "http://170.187.238.121:8000",
    baseURL: "https://api.hidenfind.com/api/v1",
    // baseURL: "http://localhost:4300/api/v1",
    crossDomain: false,
});

// Interceptor to update headers with the latest token
const updateTokenInterceptor = axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.getState()?.root?.auth?.token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// let isLoading = false;

// const setLoadingState = (loading) => {
//     isLoading = loading;
//     // If using Redux, dispatch an action here instead
//     // store.dispatch(setLoadingAction(loading));
// };

// axiosInstance.interceptors.request.use(
//     (config) => {
//         console.log('saasasasasas');
//         // Set loading to true on request start
//         setLoadingState(true);

//         const token = store.getState()?.root?.auth?.token;
//         if (token) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         setLoadingState(false);
//         return Promise.reject(error);
//     }
// );

// axiosInstance.interceptors.response.use(
//     (response) => {
//         // Set loading to false on response
//         setLoadingState(false);
//         return response;
//     },
//     (error) => {
//         setLoadingState(false);
//         return Promise.reject(error);
//     }
// );


// Function to remove the token interceptor
const removeTokenInterceptor = () => {
    axiosInstance.interceptors.request.eject(updateTokenInterceptor);
};
// console.log("LOADING----->Loading---->Loading", isLoading);

export { axiosInstance, removeTokenInterceptor };
