import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CommonInput from '../../components/common/Input'
import CommonButton from '../../components/common/Button'
import { useForm } from '../../Utils/useForm';
import { ChangePasswords } from "../../state/action/authAction";
import { useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const initialValues = {
    oldPassword:"",
    newPassword:"",
    confirmPassword:""
}

const ChangePassword = ({ handleClose }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default severity
    const navigation = useNavigate();
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        
        if ("oldPassword" in fieldValues) {
            if (fieldValues.oldPassword !== "" && fieldValues.oldPassword !== undefined) {
              if (fieldValues.oldPassword.length < 8) {
                temp.oldPassword = "Password  must be 8 charecter";
              } else {
                if (!/[0-9]/.test(fieldValues.oldPassword)) {
                  temp.oldPassword = "Password  must be 1 Number";
                } else {
                  if (!/[a-z]/.test(fieldValues.oldPassword)) {
                    temp.oldPassword = "Password  must be 1 Lowercase letter";
                  } else {
                    if (!/[A-Z]/.test(fieldValues.oldPassword)) {
                      temp.oldPassword = "Password  must be 1 Uppercase letter";
                    } else {
                      temp.oldPassword = "";
                    }
                  }
                }
              }
            } else {
              temp.oldPassword = "This field is required.";
            }
        }
    
        if ("newPassword" in fieldValues) {
          if (fieldValues.newPassword !== "" && fieldValues.newPassword !== undefined) {
            if (fieldValues.newPassword.length < 8) {
              temp.newPassword = "Password  must be 8 charecter";
            } else {
              if (!/[0-9]/.test(fieldValues.newPassword)) {
                temp.newPassword = "Password  must be 1 Number";
              } else {
                if (!/[a-z]/.test(fieldValues.newPassword)) {
                  temp.newPassword = "Password  must be 1 Lowercase letter";
                } else {
                  if (!/[A-Z]/.test(fieldValues.newPassword)) {
                    temp.newPassword = "Password  must be 1 Uppercase letter";
                  } else {
                    temp.newPassword = "";
                  }
                }
              }
            }
          } else {
            temp.newPassword = "This field is required.";
          }
        }
    
        if ("confirmPassword" in fieldValues) {
          if (fieldValues.confirmPassword !== "" && fieldValues.confirmPassword !== undefined) {
            if (fieldValues.confirmPassword !== values.newPassword) {
              temp.confirmPassword = "Passwords do not match";
            } else {
              temp.confirmPassword = "";
            }
          } else {
            temp.confirmPassword = "This field is required.";
          }
        }
      
        setErrors({
          ...temp,
        });
    
        if (fieldValues === values)
          return Object.values(temp).every((x) => x === "");
      };

    const { values, errors, setErrors, handleInputChange, resetForm } =
   useForm(initialValues, true, validate);

   const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
   const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const body ={
        oldPassword :values.oldPassword,
        newPassword :values.newPassword
      } 
     const id ="650c04ff58393e488faee639";
      const res = await ChangePasswords(id,body);
      if (res && res.status === true) {
        handleClose()
        setSnackbarSeverity('success');
        setSnackbarMessage(res.message);
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(res.message);
        setSnackbarOpen(true);
      }
    }
  };
    return (
      <>
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Change Password
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <CommonInput 
                            name ="oldPassword"
                            value ={values.oldPassword}
                            placeholder='Current Password' 
                            onChange ={handleInputChange}
                            error={errors.oldPassword}
                            helperText={errors.oldPassword}
                            inputProps={{ maxLength: 15 }}
                            passwordInput 
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput 
                        name ="newPassword"
                        value ={values.newPassword}
                        placeholder='New Password' 
                        onChange ={handleInputChange}
                        error={errors.newPassword}
                        helperText={errors.newPassword}
                        inputProps={{ maxLength: 15 }}
                        passwordInput />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput 
                        name ="confirmPassword"
                        value ={values.confirmPassword}
                        placeholder='Confirm Password' 
                        onChange ={handleInputChange}
                        error={errors.confirmPassword}
                        helperText={errors.confirmPassword}
                        inputProps={{ maxLength: 15 }} 
                        passwordInput />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='Cancle' onClick={handleClose} />
                <CommonButton buttonName='Save' onClick={handleSubmit} />
            </DialogActions>
        </div>
        <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top", // Change this to 'bottom' if needed
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      </>
        
    )
}

export default ChangePassword