import React, { useEffect, useState } from "react";
import {
  Pagination,
  Paper,
  Stack,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import {
  StyledTableCell,
  StyledChip,
  StyledTableRow,
  useStyles,
  getAlertStatus,
} from "../../../helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProductCard from "../Product/ProductCard";
import { Product1 } from "../../../helper/Constants";
import CircleIcon from "@mui/icons-material/Circle";

const CompareResult = ({ data }) => {
  const list = data || [];
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  return (
    <Box
      margin={"0"}
      sx={{
        // border: { xs: "1px solid #E9E9E9" },
        borderRadius: { md: "20px" },
        // padding: { xs: "0", sm: "20px" },
      }}
    >
      <TableContainer
        className="compare_table"
        sx={{ border: "1px solid #E9E9E9" }}
      >
        <Table stickyHeader aria-label="simple table" sx={{ height: '100%' }}>
          <TableHead>
            <TableRow>
              {width >= 900 ? <StyledTableCell className={classes.sticky}></StyledTableCell> : ''}
              {list &&
                list.length > 0 &&
                list.map((res, i) => {
                  return (
                    <StyledTableCell className="product_data" sx={{ width: list?.length === 2 ? '50%' : list?.length === 3 ? '33.33%' : '25%' }}>
                      <ProductCard
                        noIcon={true}
                        image={res.productImage && res.productImage[0]}
                        name={`${res.productName}`}
                        details={
                          <>
                            {res?.category?.masterCategoryName}
                            {/* <CircleIcon
                              sx={{
                                width: { xs: "8px", sm: "10px" },
                                height: { xs: "8px", sm: "10px" },
                                margin: "0 5px",
                              }}
                            /> */}
                            {res?.brandName[0]?.brandName}
                          </>
                        }
                      />
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {width <= 900 ?
              <StyledTableRow>
                <StyledTableCell colSpan={list?.length} component="th" scope="row" sx={{ textAlign: 'center !important', background: '#80BD9E20', fontSize: '12px !important' }}> Basic Information</StyledTableCell>
              </StyledTableRow> : ''}
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              {width >= 900 ? <StyledTableCell
                component="th"
                scope="row"
                className={`${classes.sticky} `}
              >
                Basic Information
              </StyledTableCell> : ''}
              {list &&
                list.map((res) => {
                  return (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      className=""
                    >
                      <Stack flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'space-between'} alignItems={'center'} gap={{ xs: '5px', sm: '10px', md: '20px' }}>
                        <Typography fontSize={{ xs: '3vw', sm: '14px', md: '16px' }}>{res?.brandName[0]?.brandName}</Typography>
                        <Typography fontSize={{ xs: '3vw', sm: '14px', md: '16px' }}>{res?.category?.masterCategoryName}</Typography>
                      </Stack>
                    </StyledTableCell>
                  )
                })}
            </StyledTableRow>
            {width <= 900 ?
              <StyledTableRow>
                <StyledTableCell colSpan={list?.length} component="th" scope="row" sx={{ textAlign: 'center !important', background: '#80BD9E20', fontSize: '12px !important' }}> Percentage Origin</StyledTableCell>
              </StyledTableRow> : ''}
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              {width >= 900 ? <TableCell
                component="th"
                scope="row"
                className={`${classes.sticky} product_head`}
              >
                Percentage Origin
              </TableCell> : ''}
              {list &&
                list.map((res) => {
                  return (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        className="product_data"
                      >
                        <Stack
                          flexDirection={"row"}
                          gap={{ xs: '4px', md: 1 }}
                          flexWrap={"wrap"}
                        >
                          <Box
                            sx={{
                              background: "#80BD9E",
                              color: "#fff",
                              padding: { xs: '3px 7px', md: "5px 10px" },
                              borderRadius: "5px",
                              fontSize: { xs: '10px', md: "12px" },
                            }}
                          >
                            {`Natural ${res.NaturalPercentage.toFixed(2)} %`}
                          </Box>
                          <Box
                            sx={{
                              background: "#FF755B",
                              color: "#fff",
                              padding: { xs: '3px 7px', md: "5px 10px" },
                              borderRadius: "5px",
                              fontSize: { xs: '10px', md: "12px" },
                            }}
                          >
                            {`Synthetic ${res.SyntheticPercentage.toFixed(2)}%`}
                          </Box>
                          <Box sx={{
                            background: "#FFD15B",
                            color: "#fff",
                            padding: { xs: '3px 7px', md: "5px 10px" },
                            borderRadius: "5px",
                            fontSize: { xs: '10px', md: "12px" },
                          }}>
                            {`UnCertain ${res.UncertainPercentage.toFixed(2)}%`} </Box>
                        </Stack>
                      </TableCell>
                    </>
                  );
                })}
            </StyledTableRow>
            {width <= 900 ?
              <StyledTableRow>
                <StyledTableCell colSpan={list?.length} component="th" scope="row" sx={{ textAlign: 'center !important', background: '#80BD9E20', fontSize: '12px !important' }}>Ingredients</StyledTableCell>
              </StyledTableRow> : ''}
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              {width >= 900 ? <TableCell
                component="th"
                scope="row"
                className={`${classes.sticky} product_head`}
              >
                Ingredients
              </TableCell> : ''}
              {list &&
                list.map((res) => {
                  return (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        className="product_data"
                      >
                        {res.ingredientData &&
                          res.ingredientData.length > 0 &&
                          res.ingredientData.map((res) => {
                            return <Typography fontSize={"14px"}> {res.ingredientName},</Typography>;
                          })}
                      </TableCell>
                    </>
                  );
                })}
            </StyledTableRow>
            {width <= 900 ?
              <StyledTableRow>
                <StyledTableCell colSpan={list?.length} component="th" scope="row" sx={{ textAlign: 'center !important', background: '#80BD9E20', fontSize: '12px !important' }}>Hazard</StyledTableCell>
              </StyledTableRow> : ''}
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              {width >= 900 ? <TableCell
                component="th"
                scope="row"
                className={`${classes.sticky} product_head`}
              >
                Hazard
              </TableCell> : ''}
              {list &&
                list.map((res) => {
                  return (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        className="product_data"
                      >
                        {res?.ingredientHazardsCount}
                      </TableCell>
                    </>
                  );
                })}
            </StyledTableRow>
            {width <= 900 ?
              <StyledTableRow>
                <StyledTableCell colSpan={list?.length} component="th" scope="row" sx={{ textAlign: 'center !important', background: '#80BD9E20', fontSize: '12px !important' }}>Transparency</StyledTableCell>
              </StyledTableRow> : ''}
            <StyledTableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              {width >= 900 ? <TableCell
                component="th"
                scope="row"
                className={`${classes.sticky} product_head`}
              >
                Transparency
              </TableCell> : ''}
              {list &&
                list.map((res) => {
                  return (
                    <>
                      <TableCell
                        component="th"
                        scope="row"
                        className="product_data"
                      >
                        {res?.transparency}
                      </TableCell>
                    </>
                  );
                })}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompareResult;
